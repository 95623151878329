import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import serverURL from '../../server-config';
import React, { useEffect, useState } from 'react';
import './style.css'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
function UserCard() {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const {healthId} = useParams();
    const [sendingMail,setSendingMail] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${serverURL}/getuserDetails/${healthId}`);
                setData(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    const sendToMail = async ()=>{
        if(sendingMail){
            return;
        }
        setSendingMail(true);
        await axios.post(`${serverURL}/sendCardURL`,{to:data.email,subject:'You can conform your card via this link',url:window.location.href+window.location.pathname}).then((result) => {
            toast.success("Send the url successfully");
            setSendingMail(false);
        }).catch((err) => {
            toast.error("Error while sending the card");
            setSendingMail(false);
        });;  
    }
    return (
        <div className='w-full h-full flex justify-center items-center flex-col gap-4 p-4'>
            <ToastContainer/>
            {data.paymentStatus && <div className='flex lg:flex-row flex-col w-full lg:w-1/2  border-2 border-gray-200 p-5 lg:items-start items-center justify-center lg:justify-around light-green rounded-md'>
                <div className='flex flex-col items-center'>
                    <div className='h-[200px] w-[200px] rounded-lg border profile' style={{ backgroundImage: `url(${data.image})` }}>
                    </div>
                    <div className='font-bold'>{data.healthId}</div>
                </div>
            
                <div className='flex flex-col gap-3 w-2/3 p-3'>
                    <div className=''><span className='font-bold'>Name : </span>{data.name}</div>
                    <div className=''><span className='font-bold'>Gender : </span>{data.gender}, {data.age}</div>
                    <div className=''><span className='font-bold'>Number : </span>{data.number}</div>
                    <div className=''><span className='font-bold'>Email : </span>{data.email}</div>
                    <div className=''><span className='font-bold'>Address : </span>{data.address}, {data.city}, {data.pincode}</div>
                </div>
            </div>}
            {!data.paymentStatus && <div className='text-center'>
                <div className='font-bold text-2xl'>{data.name}</div>    
                <div className=''>Please complete the payment</div>
                <div className=''>If already paid? Please send your payment screen shot to this mail</div>
                <div className=''>healthkard99@gmail.com</div>
            </div>}
            {localStorage.getItem('agentID')&&<div className='flex lg:w-1/2 w-full justify-between flex-col lg:flex-row-reverse gap-4'>
                <div onClick={sendToMail} className='green text-white p-2 rounded-md hover:cursor-pointer text-center '>{sendingMail?<div className='flex gap-2 items-center justify-center'><ClipLoader color={"#fff"} size={20} aria-label="Loading Spinner" data-testid="loader"/>Sending Mail</div>:'Send to mail'}</div>
                <Link to={`/newUser/${localStorage.getItem('agentID')}` }className='blue text-white p-2 rounded-md hover:cursor-pointer text-center'>Add another user</Link>
            </div>}
        </div>
    );
}

export default UserCard;
