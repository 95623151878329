import React,{useEffect,useState} from 'react';
import {ref, uploadBytesResumable, getDownloadURL, deleteObject}  from 'firebase/storage'
import {auth, storage} from '../../firebase-config';
import { RxCross2 } from "react-icons/rx";
import OTPInput from "otp-input-react";
import PhoneInput from 'react-phone-input-2'
import ClipLoader from "react-spinners/ClipLoader";
import 'react-phone-input-2/lib/style.css'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import serverURL from '../../server-config'
import { CiEdit } from "react-icons/ci";
function Form1() {
  const [servicesOffered, setServicesOffered] = useState([
    "All Services",
    "Bones",
    "Children (Pediatrics)",
    "Dental",
    "Ears",
    "Eyes",
    "Gastro",
    "General Medicine",
    "Hair",
    "Heart",
    "Kidney",
    "Lungs",
    "Neuro",
    "Nose",
    "Reproductive",
    "Skin"
]);

  const [isAgent,setIsAgent] = useState(false);
  const [hospitalDetails, setHospitalDetails] = useState(() => {
    const storedHospitalDetails = localStorage.getItem('hospitalDetails');
    return storedHospitalDetails ? JSON.parse(storedHospitalDetails) : {
      daysAvailabilty: [true,true,true,true,true,true,false],
      from: '08:00',
      gstNumber: '',
      hospitalGSTFile: '',
      hospitalLegalName: '',
      hospitalLicense: '',
      hospitalNumber: '',
      hospitalOwnerContactNumber: '',
      hospitalOwnerEmail: '',
      hospitalOwnerFullName: '',
      hospitalTradeName: '',
      licenseNumber: '',
      address: {
        lat:'',
        lng:'',
        street:'',
        landmark:'',
        city:'',
        state:'',
        country:'',
        code:''
      },
      typeOfHospital:'',
      servicesOffered: [],
      to: '20:00'
      };
  });
  const [hospitalNumber,setHospitalNumber] = useState({
    number:'',
    sending:false,
    otpSent:false,
    verifying:false,
    enteredOtp:'',
    verified:false,
  })
  const [doctorNumber,setDoctorNumber] = useState({
    number:'',
    sending:false,
    otpSent:false,
    verifying:false,
    enteredOtp:'',
    verified:false,
  })
  const [agentId,setAgentID] = useState('');
  const [uploading,setUploading] = useState(false);
  const addService = (e)=>{
    let temp = hospitalDetails.servicesOffered;
    temp.push(e.target.value);
    setHospitalDetails({...hospitalDetails,servicesOffered:temp})
  }
  const deleteService = (index) => {
    const updatedServices = hospitalDetails.servicesOffered.filter((service, i) => i !== index);
    setHospitalDetails({ ...hospitalDetails, servicesOffered: updatedServices });
  };

  const handleInputChange = (e)=>{
    setHospitalDetails(previous=>({
      ...previous,
      [e.target.name]: e.target.value
    }));
  }
  const upload = (e) => {
    setUploading(true);
    let selectedFile = e.target.files[0];
    if (selectedFile) {
        const imageRef = ref(storage, 'Hospital/'+localStorage.getItem('hospitalId')+'/' + e.target.name + '/' + selectedFile.name);
        const uploadTask = uploadBytesResumable(imageRef, selectedFile);
        uploadTask.on('state_changed',(snapshot) => {
            },
            (error) => {
                console.error('Error uploading image:', error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((imageUrl) => {
                    setHospitalDetails(previous=>({
                        ...previous,
                        [e.target.name]:imageUrl
                    }));   
                    setUploading(false);
                });
            }
        );
    } else {
        alert('Please select an image to upload.');
        setUploading(false);
    }
  };
  const deleteFile = (filePath,type) => {
    const fileRef = ref(storage, filePath);
    deleteObject(fileRef).then((result) => {
      setHospitalDetails(previous=>({
          ...previous,
          [type]:''
      }));
    }).catch((err) => {
        console.error('Error deleting file:', err);
    });        
  };
  const [user1,setUser1] = useState(null);
  const [user2,setUser2] = useState(null);
  const handleSendOTP = async (number) => {
    try {
      if(number === 1){
        if(hospitalNumber.number.length<12){
          toast.error("Please enter correct number")
          return;
        }
        setHospitalNumber(previous=>({
          ...previous,
          sending:true
        }))
        const recaptcha = new RecaptchaVerifier(auth, "recaptcha1", {'size': 'invisible'});
        const confirmation = await signInWithPhoneNumber(auth, '+' + hospitalNumber.number, recaptcha);  
        if (confirmation) {
          setUser1(confirmation);
          setHospitalNumber(previous=>({
            ...previous,
            sending:false,
            otpSent:true
          }))
        }
      }
      else{
        if(doctorNumber.number.length<12){
          toast.error("Please enter correct number");
          return;
        }
        setDoctorNumber(previous=>({
          ...previous,
          sending:true
        }))
        const recaptcha = new RecaptchaVerifier(auth, "recaptcha2", {'size': 'invisible'});
        const confirmation = await signInWithPhoneNumber(auth, '+' + doctorNumber.number, recaptcha);  
        if (confirmation) {
          setUser2(confirmation);
          setDoctorNumber(previous=>({
            ...previous,
            sending:false,
            otpSent:true
          }))
        } 
      }
    } catch (error) {
      console.log(error)
      toast.error('Error while sending otp');
    }
  }  
  const handleVerifyOTP = async (number) => {
    try {
      if(number===1){
        setHospitalNumber(previous=>({
          ...previous,
          verifying:true
        }))
        await user1.confirm(hospitalNumber.enteredOtp);
        setHospitalNumber(previous=>({
          ...previous,
          verified:true,
          verifying:false
        }))
        setHospitalDetails(previous=>({
          ...previous,
          hospitalNumber:hospitalNumber.number
        }))
      }
      else{
        setDoctorNumber(previous=>({
          ...previous,
          verifying:true
        }))
        await user2.confirm(doctorNumber.enteredOtp);
        setDoctorNumber(previous=>({
          ...previous,
          verified:true,
          verifying:false
        }))
        setHospitalDetails(previous=>({
          ...previous,
          hospitalOwnerContactNumber:doctorNumber.number
        }))
      }
    } catch(error) {
      setDoctorNumber(previous=>({
        ...previous,
        verifying:false
      }));
      setHospitalNumber(previous=>({
        ...previous,
        verifying:false
      }));
      toast.error("Please re-enter the correct otp");
    }
  }
  const handleDaysAvailabilty = (index)=>{
    let temp = hospitalDetails.daysAvailabilty;
    temp[index] = !temp[index];
    setHospitalDetails(previous=>({
      ...previous,
      daysAvailabilty:temp
    }))
  }
  const verifyAgent = ()=>{
    console.log(agentId)
    axios.get(`${serverURL}/checkAgent/${agentId}`).then((result) => {
      localStorage.setItem('hospitalRegisterAgentId',agentId);
      toast.success("Agent added successfully ")
    }).catch((err) => {
      toast.error("Agent Doesn't exists");
    });
  }
  
  // use effect's
  useEffect(()=>{
    localStorage.setItem('hospitalDetails',JSON.stringify(hospitalDetails));
  },[hospitalDetails])
  useEffect(()=>{
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setHospitalDetails({...hospitalDetails,address:{...hospitalDetails.address,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }});
      });
    }
  },[])
  const days = ['M','T','W','Th','Fr','Sa','Su'];
  return (
    <div className='relative  lg:w-4/5 mx-auto'>
      <ToastContainer/>
      <div className='text-2xl lg:text-4xl mt-7 font-medium'>Hospital details.</div>
      {!uploading&&<div className='w-full p-2 flex flex-col mt-10 gap-8'>
        {/* Hospital details here  */}
        <div className='w-full flex flex-col gap-5 shadow-md p-2 lg:p-4 rounded-md'>
          <div className="relative w-full min-w-[200px] h-10">
          <input name="hospitalLegalName" value={hospitalDetails.hospitalLegalName} onChange={handleInputChange} autoFocus className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-md p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
          <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-2 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-2 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Hospital Legal Name <span className='font-bold text-red-600'> *</span></label>
        </div>
        <div className="relative w-full min-w-[200px] h-10">
          <input name="hospitalTradeName" value={hospitalDetails.hospitalTradeName} onChange={handleInputChange} className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
          <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Hospital Trade Name <span className='font-bold text-red-600'>*</span></label>
        </div>
        <div className="relative w-full min-w-[200px] h-10">
          <input name="gstNumber" value={hospitalDetails.gstNumber} onChange={handleInputChange} className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
          <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">GST Number</label>
        </div>
        {!hospitalDetails.hospitalGSTFile && <label htmlFor="hospitalGSTFile" className="hover:cursor-pointer blue text-white h-10 flex justify-center items-center rounded-md">
          <span>Upload GST File</span>
          <input type="file" id="hospitalGSTFile" onChange={upload} name='hospitalGSTFile' className="hidden" accept=".pdf"/>
        </label>}
        {hospitalDetails.hospitalGSTFile && <div className=' flex items-center bg-gray-400 w-fit p-1 rounded-md justify-center gap-2 text-white'><a rel="noopener noreferrer" href={hospitalDetails.hospitalGSTFile} target='_blank' className=' flex items-center'>Click here to see the image</a><div className='hover:cursor-pointer hover:text-red-400'><RxCross2 onClick={()=>deleteFile(hospitalDetails.hospitalGSTFile,'hospitalGSTFile')} className=''/></div> </div>}
        <div className="relative w-full min-w-[200px] h-10">
          <input name="licenseNumber" value={hospitalDetails.licenseNumber} onChange={handleInputChange} className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
          <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">License Number<span className='font-bold text-red-600'>*</span></label>
        </div>
        {!hospitalDetails.hospitalLicense && <label htmlFor="hospitalLicense" className="hover:cursor-pointer blue text-white h-10 flex justify-center items-center rounded-md">
          <span>Upload License File<span className='font-bold text-red-600'>*</span></span>
          <input type="file" id="hospitalLicense" onChange={upload} name='hospitalLicense' className="hidden" accept=".pdf"/>
        </label>}
        {hospitalDetails.hospitalLicense && <div className=' flex items-center bg-gray-400 w-fit p-1 rounded-md justify-center gap-2 text-white'><a rel="noopener noreferrer" href={hospitalDetails.hospitalLicense} target='_blank' className=' flex items-center'>Click here to see the image</a><div className='hover:cursor-pointer hover:text-red-400'><RxCross2 onClick={()=>deleteFile(hospitalDetails.hospitalLicense,'hospitalLicense')} className=''/></div> </div>}
        </div>
        {/* Hospital address here  */}
        {hospitalDetails.address && <div className='flex flex-col gap-5 shadow-md p-2 lg:p-4 rounded-md'>
          <div className='text-xl lg:text-2xl font-semibold'>Please enter the location details of the hospital<span className='font-bold text-red-600'>*</span></div>
          <div className="w-full flex flex-wrap gap-4 justify-between">
            <div className="relative w-5/12 min-w-[200px] h-10 ">
              <input name="street" value={hospitalDetails.address.street} onChange={(e)=>setHospitalDetails({...hospitalDetails,address:{...hospitalDetails.address,street:e.target.value}})} className="border peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900" placeholder=" " />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Enter Street address</label>
            </div>
            <div className="relative w-5/12 min-w-[200px] h-10 ">
              <input name="landmark" value={hospitalDetails.address.landmark} onChange={(e)=>setHospitalDetails({...hospitalDetails,address:{...hospitalDetails.address,landmark:e.target.value}})} className="border peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900" placeholder=" " />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Enter Land Mark</label>
            </div>
            <div className="relative w-5/12 min-w-[200px] h-10 ">
              <input name="city" value={hospitalDetails.address.city} onChange={(e)=>setHospitalDetails({...hospitalDetails,address:{...hospitalDetails.address,city:e.target.value}})} className="border peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900" placeholder=" " />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Enter City</label>
            </div>
            <div className="relative w-5/12 min-w-[200px] h-10 ">
              <input name="state" value={hospitalDetails.address.state} onChange={(e)=>setHospitalDetails({...hospitalDetails,address:{...hospitalDetails.address,state:e.target.value}})} className="border peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900" placeholder=" " />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Enter State</label>
            </div>
            <div className="relative w-5/12 min-w-[200px] h-10 ">
              <input name="country" value={hospitalDetails.address.country} onChange={(e)=>setHospitalDetails({...hospitalDetails,address:{...hospitalDetails.address,country:e.target.value}})} className="border peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900" placeholder=" " />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Enter Country</label>
            </div>
            <div className="relative w-5/12 min-w-[200px] h-10 ">
              <input name="code" value={hospitalDetails.address.code} onChange={(e)=>setHospitalDetails({...hospitalDetails,address:{...hospitalDetails.address,code  :e.target.value}})} className="border peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900" placeholder=" " />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Enter Postal code</label>
            </div>
          </div>  
        </div>}
        {/* Hospital contact details here  */}
        <div className='w-full flex flex-col gap-4 p-2 lg:p-4 rounded shadow-md'>
          <div className='text-xl lg:text-2xl font-semibold'>Contact Information of your Hospital<span className='font-bold text-red-600'>*</span>.</div>
          <div className='text-sm'>Your customer contact to this number</div>
          <div className='w-full flex items-center gap-4 lg:gap-6 md:flex-row flex-col  flex-wrap '>
            <PhoneInput country={'in'} name="contactNumber" value={hospitalDetails.hospitalNumber} onChange={number=>setHospitalNumber({...hospitalNumber,number:number})} disabled={hospitalNumber.verified || hospitalDetails.hospitalNumber}/>
            { !hospitalDetails.hospitalNumber && !hospitalNumber.otpSent && !hospitalNumber.verified && <div onClick={()=>handleSendOTP(1)} className='p-2 w-fit gap-3 flex justify-center items-center blue text-white rounded-md hover:cursor-pointer'>
              <ClipLoader loading={hospitalNumber.sending} color="#fff"  size={20} aria-label="Loading Spinner" data-testid="loader"/>
              {!hospitalNumber.sending?'SEND OTP':'SENDING OTP'}
            </div>}
            {hospitalNumber.otpSent && !hospitalNumber.verified && <OTPInput autoFocus OTPLength={6} inputClassName='border b-blue' otpType="number" disabled={false} secure value={hospitalNumber.enteredOtp} onChange={otp=>setHospitalNumber({...hospitalNumber,enteredOtp:otp})} />}
            {hospitalNumber.otpSent && !hospitalNumber.verified && <div onClick={()=>handleVerifyOTP(1)} className='p-2 w-fit gap-3 flex justify-center items-center blue text-white rounded-md hover:cursor-pointer'>
              <ClipLoader color="#fff" loading={hospitalNumber.verifying}  size={20} aria-label="Loading Spinner" data-testid="loader"/>
              {!hospitalNumber.verifying ? 'VERIFY OTP':'VERIFYING OTP'}
            </div>}
            {hospitalNumber.otpSent && !hospitalNumber.verified && <div className='flex gap-2 items-center'>Edit phone number <div className='hover:bg-gray-200 p-2 rounded-full hover:cursor-pointer' onClick={()=>window.location.reload()}><CiEdit /></div></div>}
            {hospitalNumber.verified && <div className='p-2 w-fit gap-3 flex justify-center items-center text-green rounded-md hover:cursor-pointer text-xl font-bold'>Verified</div>}
            <div id="recaptcha1" className=''></div>
          </div>
        </div>
        {/* Hospital timings details here  */}
        <div className='w-full flex flex-col gap-4 p-2 lg:p-4  rounded shadow-md'>
          <div className='text-xl lg:text-2xl font-semibold'>Hospital working and services offered<span className='font-bold text-red-600'>*</span>.</div>
          <div className='flex items-center gap-2'>
            <div className='text-sm md:text-xl'>Availabe on</div>
            <div className='flex gap-1 m-1 p-1 md:gap-4'>
              {hospitalDetails.daysAvailabilty.map((day,index)=>{
                return(
                  <div key={index} onClick={()=>handleDaysAvailabilty(index)} className={`flex text-sm lg:text-md hover:cursor-pointer shadow-md h-[20px] lg:h-[30px] w-[20px] lg:w-[30px] p-3 rounded-full justify-center items-center ${day ? 'green text-white' : 'bg-white text-black'}`}>
                    {days[index]}
                  </div>
                );})
              }
            </div>
          </div>
          <div className='flex flex-col mt-1 p-1 gap-1'>
            <div className='flex flex-col  gap-2 md:gap-8'>
              <div className='font-semibold'>Time (Optional)</div>
              <div className='flex gap-2 justify-between w-1/2'>
                <div className=''>From  </div>
                <div className=''>
                  <input name="from" value={hospitalDetails.from||"08:00"} onChange={handleInputChange} type='time'/>
                </div>
              </div>
              <div className='flex gap-2 justify-between w-1/2'>
                <div className=''>To  </div>
                <div className=''>
                  <input name="to"  value={hospitalDetails.to||"20:00"} onChange={handleInputChange} type='time'/>
                </div>
              </div>
            </div>
            <div className='text-green text-xs'>Note : This may vary according to the hospital</div>
          </div>
          <div className='flex flex-col lg:flex-row justify-between m-1 w-full'>
            <div className='flex gap-2 flex-col'>
              <div className=''>Type of Hospital <span className='font-bold text-red-600'>*</span></div>
              <div className="relative w-full min-w-[200px] h-10">
                <select name='typeOfHospital' value={hospitalDetails.typeOfHospital} onChange={handleInputChange} className='border p-1'>
                  <option className='p-1'>--select--</option>
                  <option value='Multi Speciality Hospital' className='light-green p-2'> Multi Speciality Hospital</option>
                  <option value='Super Speciality' className='p-1'>Super Speciality </option>
                  <option value='Others' className='p-1 light-green '>Others</option>
                </select>
              </div>
            </div>
            
            <div className='flex gap-2 flex-col'>
              <div className=''>Services Offered<span className='font-bold text-red-600'>*</span></div>
              <div className="relative w-full min-w-[200px] h-10">
                <select name='servicesOffered' value={hospitalDetails.servicesOffered} onChange={addService} className='border p-1'>
                  <option>--select---</option>
                  {servicesOffered.map((service,index)=>{
                    return(
                      <option key={index} value={service}>{service}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className='flex flex-wrap gap-3'>
                  {
                    hospitalDetails.servicesOffered.map((service,index)=>{
                      return(
                        <div index={index} className='p-1 w-fit bg-gray-400 rounded-md  text-white flex items-center gap-2 text-sm'>
                          <div className=''>{service}</div>
                          <div onClick={()=>deleteService(index)} className='hover:bg-white hover:text-red-500 rounded-full hover:cursor-pointer p-1'><RxCross2/></div>
                        </div>
                      )
                    })
                  }
          </div>
          <div className='text-green text-sm'>Note : Customer  can find services of your hospital based on this </div>
        </div>
        {/* Hospital owner details here  */}
        <div className='w-full flex flex-col gap-4  p-2 lg:p-4 rounded shadow-md'>
          <div className='text-xl lg:text-2xl font-semibold'>Details of Hospital Ownership</div>
          <div className='text-sm'>These details  will be utilized for sharing business-related communications and will NOT DISPLAY anywhere.</div>
          <div className='w-full flex items-center gap-4 lg:gap-6 md:flex-row flex-col  flex-wrap '>
            <div className=''>Owners Contact<span className='font-bold text-red-600'>*</span></div>
            <PhoneInput country={'in'} name="contactNumber" value={hospitalDetails.hospitalOwnerContactNumber} onChange={number=>setDoctorNumber({...doctorNumber,number:number})} disabled={doctorNumber.verified || hospitalDetails.hospitalOwnerContactNumber}/>
            {!hospitalDetails.hospitalOwnerContactNumber && !doctorNumber.otpSent && !doctorNumber.verified && <div onClick={()=>handleSendOTP(2)} className='p-2 w-fit gap-3 flex justify-center items-center blue text-white rounded-md hover:cursor-pointer'>
              <ClipLoader loading={doctorNumber.sending} color="#fff"  size={20} aria-label="Loading Spinner" data-testid="loader"/>
              {!doctorNumber.sending?'SEND OTP':'SENDING OTP'}
            </div>}
            {doctorNumber.otpSent && !doctorNumber.verified && <OTPInput autoFocus OTPLength={6} inputClassName='border b-blue' otpType="number" disabled={false} secure value={doctorNumber.enteredOtp} onChange={otp=>setDoctorNumber({...doctorNumber,enteredOtp:otp})} />}
            {doctorNumber.otpSent && !doctorNumber.verified && <div onClick={()=>handleVerifyOTP(2)} className='p-2 w-fit gap-3 flex justify-center items-center blue text-white rounded-md hover:cursor-pointer'>
              <ClipLoader color="#fff" loading={doctorNumber.verifying}  size={20} aria-label="Loading Spinner" data-testid="loader"/>
              {!doctorNumber.verifying ? 'VERIFY OTP':'VERIFYING OTP'}
            </div>}
            {doctorNumber.otpSent && !doctorNumber.verified && <div className='flex gap-2 items-center'>Edit phone number <div className='hover:bg-gray-200 p-2 rounded-full hover:cursor-pointer' onClick={()=>window.location.reload()}><CiEdit /></div></div>}
            {doctorNumber.verified && <div className='p-2 w-fit gap-3 flex justify-center items-center text-green rounded-md hover:cursor-pointer text-xl font-bold'>Verified</div>}
            <div id="recaptcha2" className=''></div>
          </div>
          <div className='flex-col  my-4 w-full md:flex-row  gap-4'>
            <div className="relative min-w-[200px] h-10">
              <input name="hospitalOwnerFullName" value={hospitalDetails.hospitalOwnerFullName} onChange={handleInputChange} className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-4 py-4.5 rounded-[7px]  focus:border-gray-900" placeholder=" " />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Owners Full Name
              <span className='font-bold text-red-600'>*</span></label>
            </div>
            <div className="relative min-w-[200px] mt-4 h-10">
              <input name="hospitalOwnerEmail" value={hospitalDetails.hospitalOwnerEmail} onChange={handleInputChange} className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px]  focus:border-gray-900" placeholder=" " />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Owners Email ID
              <span className='font-bold text-red-600'>*</span></label>
            </div>
          </div>
        </div>
        {/*agent details here  */}
        {!localStorage.getItem('hospitalRegisterAgentId') && <div className='w-full flex flex-col gap-4  p-2 lg:p-4 rounded shadow-md'>
            <div className="flex items-center mb-4">
              <input id="default-checkbox" type="checkbox" value={isAgent} onChange={()=>{setIsAgent(!isAgent);}} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
              <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-balck">Are you an agent of Healthkard ?</label>
            </div>
            {isAgent &&<div className='flex items-center gap-2 md:flex-row flex-col '>
              <div className="relative w-1/2 min-w-[200px] h-10">
                  <input name='agentID' onChange={(e)=>setAgentID(e.target.value)} className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Enter Agent ID</label>
              </div>
              <div onClick={verifyAgent} className='blue p-2 text-white rounded-md hover:cursor-pointer'>VERIFY AGENT</div>
            </div>}
        </div>}
      </div>}
      {uploading&&<div className=''>Please wait uploading the file</div>}
    </div>
  )
}

export default Form1