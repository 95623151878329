import React from 'react'
import logo from '../assets/logo.svg'
import { FaInstagram, FaLinkedin } from 'react-icons/fa'
import { MdOutlineAlternateEmail } from "react-icons/md";
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='bg-black text-white items-center flex flex-col p-4 w-full'>
        <div className='flex md:flex-row flex-col-reverse justify-around w-full gap-6 p-4'>
          <div className='flex flex-col w-full md:w-fit'>
            <div className='flex gap-2 flex-col items-center'>
              <img src={logo} alt='logo' width='100px'/>
              <div className='font-bold text-green uppercase'>HealthKard</div>
            </div>
            <div className='flex w-full justify-around p-2'>
              <a href='https://www.instagram.com/healthkard.in' target='_blank' rel='noreferrer' className='p-2 hover:bg-gray-200 rounded-full cursor-pointer hover:text-black'>
                <FaInstagram/>
              </a>
              <a href='https://www.linkedin.com/company/healthkard/' target='_blank' rel='noreferrer' className='p-2 hover:bg-gray-200 rounded-full cursor-pointer hover:text-black'>
                <FaLinkedin/>
              </a>
              <a href='healthkard99@gmail.com' target='_blank' rel='noreferrer' className='p-2 hover:bg-gray-200 rounded-full cursor-pointer hover:text-black'>
                <MdOutlineAlternateEmail/>
              </a>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='font-bold text-left md:text-center'>Contact Us</div>
            <div className='text-centerfont-thin'>+91 78427 22245</div>
            <div className='text-centerfont-thin'>support@healthkard.in</div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='font-bold text-left md:text-center'>who are we</div>
            <div className='font-thin'>Investor Relations</div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='font-bold text-left md:text-center'>For Hospitals</div>
            <div className='text-centerfont-thin'>Partner with us</div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='font-bold text-left md:text-center'>Legal</div>
            <Link to='termsAndConditions' className='text-centerfont-thin hover:text-blue-400 hover:cursor-pointer'>Terms & Conditions</Link>
            <Link to='privacyPolicy' className='text-centerfont-thin hover:text-blue-400 hover:cursor-pointer'>Privacy Policy</Link>
            <Link to='refundPolicy' className='text-centerfont-thin hover:text-blue-400 hover:cursor-pointer'>Refund Policy</Link>
          </div>
        </div>
        <div className='text-center'>&copy;Copyright @2024 Healthkard Powered by Halekard Private Limited</div>
    </div>
  )
}

export default Footer