import React, { useState ,useEffect} from 'react'
import serverURL from '../../server-config';
import axios from 'axios';
import logo from '../../assets/logo.svg'
import UserTable from './UserTable';
import './style.css'
import { IoIosRefresh } from "react-icons/io";

import { Outlet, useParams } from 'react-router-dom';
function UsersInfo() {
    const {healthId}  = useParams();
    const [data,setData] = useState([]);
    const [stats,setStats] = useState({user:0,active:0});
    const [filteredData,setFilteredData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [reducer,setReducer] = useState(0);
    useEffect(()=>{
        let fetchData =async ()=>{
            setLoading(true)
            await axios.get(`${serverURL}/getUserNameID`).then((result) => {
                if(result.data){
                    setData(result.data.reverse());
                    setFilteredData(result.data);
                    setLoading(false)
                }
            }).catch(error=>{
                console.log(error);
            })
            await axios.get(`${serverURL}/userStats`).then((result) => {
                setStats(result.data);
            })
        }
        fetchData();
    },[reducer])
    
    // functions
    const filter = (term)=>{
        const newData = data.filter(row=>{
            return (row.healthId.toLowerCase().includes(term.toLowerCase())||
            row.name.toLowerCase().includes(term.toLowerCase()));
        })
        setFilteredData(newData);
    }
  return (
    <div className='flex p-4 gap-12 justify-center'>
        <div className='flex flex-col w-1/4  gap-3'>
            <div className='flex lg:text-xl gap-10'>
                <div className='flex flex-col w-1/2 p-4 border-gray-100 rounded-xl border shadow-xl'>
                    <div className='font-semibold'>Total Users</div>
                    <div className='text-green font-bold'>{stats.user}</div>
                </div>
                <div className='flex flex-col w-1/2 p-4 border-gray-100 rounded-xl border shadow-xl'>
                    <div className='font-semibold'>Active Users</div>
                    <div className='text-green font-bold'>{stats.active}</div>
                </div>
            </div>
            <input type='search' onChange={(e)=>filter(e.target.value)}  placeholder='Search with HealthKard ID or Hospital Name' className='border border-gray-100 shadow-lg w-full p-2 rounded-md'/>
            <div onClick={()=>setReducer(prev=>prev+1)} className='flex items-center p-2 gap-2 rounded-md border self-end w-fit hover:cursor-pointer'><IoIosRefresh/>Refresh</div>
            {!loading?<UserTable data={filteredData} dataOf={'User'}/>:<div>Loading</div>}
        </div>
        {healthId && <Outlet/>}
        {!healthId && <div className='flex flex-col w-2/3 gap-10 justify-center items-center h-[80vh]'>
            <img src={logo} alt='logo' width='300px'/>
            <div className='font-bold text-3xl text-green'>HealthKard</div>
        </div>}
    </div>
  )
}

export default UsersInfo