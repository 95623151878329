import React from 'react'

function UserInfoTable({data}) {
  return (
    <div className='w-full h-[40vh]  overflow-scroll'>
        <div className='flex blue sticky top-0 text-white p-2 rounded mb-4'>
            <div className='w-1/12'>Sno</div>
            <div className='w-3/12'>Hospital Id</div>
            <div className='w-4/12'>Hospital Name</div>
            <div className='w-3/12'>Last visit</div>
            <div className='w-1/12'>Frequency</div>
        </div>
        {data&&
            data.map((d,i)=>{
                return(
                    <div key={i} className={`flex  ${i%2===0?'light-green' :''} hover:bg-[#00BFA8] hover:text-white hover:cursor-pointer  p-2 rounded`}>
                        <div className='w-1/12'>{i+1}</div>
                        <div className='w-3/12'>{d.hospitalId}</div>
                        <div className='w-4/12'>{d.hospitalName}</div>
                        <div className='w-3/12'>{(new Date(d.lastVisit)).toISOString().split('T')[0]}</div>
                        <div className='w-1/12 text-center'>{d.frequency+1}</div>
                    </div>
                )
            })
        }
    </div>
  )
}

export default UserInfoTable