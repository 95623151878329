import React from 'react'
import Logo from '../../Logo'
import { Link, useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate  = useNavigate();
  const logout=()=>{
    localStorage.removeItem('agentID');
    navigate('/agentLogin');
  }
  return (
    <Link to='/' className='green p-2 static top-0 left-0 z-10 flex justify-between'>
        <Logo/>
        {localStorage.getItem('agentID') && <div onClick={logout} className='bg-red-800 p-2 text-white rounded-md hover:cursor-pointer'>Logout</div>}
    </Link>
  )
}

export default Navbar