import React, { useRef, useState } from 'react'
import './style.css'
import img1 from '../assets/about/img1.png'
import img2 from '../assets/about/img2.png'
import img3 from '../assets/about/img3.png'
import img4 from '../assets/about/img4.png'
import img5 from '../assets/about/img5.png'
import img6 from '../assets/img6.png'
import aboutimg from '../assets/aboutimg.png'
import contactus from '../assets/backedby/contactus.gif';
import { GoMoveToTop } from "react-icons/go";
import Logo from '../Logo'
import { Link, Outlet } from 'react-router-dom'
import Footer from '../components/Footer'
import serverURL from '../server-config'
import { ToastContainer,toast } from 'react-toastify'
import axios from 'axios'
import { CiMenuFries } from 'react-icons/ci'
import { RxCross1 } from 'react-icons/rx'
function HospitalCredentails() {
  const aboutUsRef = useRef(null);
  const contactUsRef = useRef(null);
  const stepToJoinRef = useRef(null);
  const topRef = useRef(null);
  const [userData,setUserData] = useState({
    name:"",
    email:"",
    phoneNumber:"",
    description:""
  })
  const handleChangeInput = (e)=>{
    setUserData(prev=>({
      ...prev,
      [e.target.name]: [e.target.value]
    }))
  }
  const submit = async()=>{
    if(!userData.phoneNumber || !userData.description || !userData.name){
      toast.error("Please fill all the details");
      return;
    }
    await axios.post(`${serverURL}/sendMail`,{subject:`${userData.name} from ${userData.hospitalName} has sent a mail`,message:`${userData.description}\n Contact details :\n ${userData.email} \n ${userData.phoneNumber}`}).then((result) => {
      toast.success("Message sent successfully our team will contact soon");
      setUserData({
        name:"",
        hospitalName:"",
        email:"",
        phoneNumber:"",
        description:""
      })
    }).catch((err) => {
      toast.error("Some thing wents wrong");
    });
  }

  const handleScroll = (ref) => {
      if (ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth' });
          setShowPopup(false)
      }
  }
  const [showPopup,setShowPopup] = useState(false)
  return (
    <div className='overflow-scroll flex flex-col'>
      <ToastContainer/>
      <div className='fixed top-0 left-0 w-full'>
          <Outlet/>
      </div>
      <div ref={topRef} className='banner'>
        <div className='flex items-center justify-between p-3'>
          <div className=''>
            <Logo/>
          </div>
          <div className='hidden md:flex w-1/2 justify-around text-white'>
            <Link to='/' className='font-semibold'>Home</Link>
            <div onClick={()=>handleScroll(stepToJoinRef)} className='hover:cursor-pointer font-semibold'>Steps to Join</div>
            <div onClick={()=>handleScroll(aboutUsRef)} className='hover:cursor-pointer font-semibold'>About Us</div>
            <div onClick={()=>handleScroll(contactUsRef)} className='hover:cursor-pointer font-semibold'>Contact Us</div>
          </div>
          <Link to='/userRenewalFrom/newUser' className='blue text-white font-semibold p-2 rounded-md'>Get Your Kard</Link>
          <div onClick={()=>setShowPopup(true)} className='lg:hidden p-2 text-white font-bold'><CiMenuFries/></div>
          {showPopup && <div className='flex flex-col p-4 lg:hidden z-10 w-1/2 text-black bg-white fixed right-4 top-4'>
            <div onClick={()=>setShowPopup(false)} className='text-red-600 p-2 flex justify-end'>
              <RxCross1/>
            </div>
            <div className='flex flex-col w-full justify-around p-2'>
              <Link to='/' className='p-1 hover:cursor-pointer font-semibold'>Home</Link>
              <div onClick={()=>handleScroll(stepToJoinRef)} className='p-1 hover:cursor-pointer font-semibold'>Steps to Join</div>
              <div onClick={()=>handleScroll(aboutUsRef)} className='p-1 hover:cursor-pointer font-semibold'>About Us</div>
              <div onClick={()=>handleScroll(contactUsRef)} className='p-1 hover:cursor-pointer font-semibold'>Contact Us</div>
            </div>
          </div>}
        </div>
        <div className='gap-5 flex flex-col font-bold text-white h-3/4 justify-center items-center'>
          <div className='md:text-2xl lg:text-5xl  flex flex-col gap-2'>
            <div className=''>Together Towards Better Health</div>
            <div className=''>Partner with <span className='text-green'>Healthkard </span> Today</div>
          </div>
          <Link to='login' className='blue rounded-md p-3'>Login to View Your Hospital</Link>
          <Link to='signup' className='font-bold underline text-xl  hover:cursor-pointer'>Register Your Hospital Now</Link>
        </div>
      </div>
      <div ref={stepToJoinRef} className='flex flex-col gap-12  items-center justify-around my-12'>
        <div className='flex flex-col gap-2 items-center'>
          <div className='font-bold text-2xl'>Steps to Join Our Community</div>
          <div className='hidden md:block'>Team up with us for a healthier community focused on well-being for all.</div>
        </div>
        <div className='flex md:flex-row flex-col w-2/3  justify-around h-2/3 gap-3  p-3'>
          <div className='flex flex-col gap-5 items-center justify-center w-full md:w-1/4 shadow-md border p-4 rounded-md'>
            <div className='font-bold green text-white w-[40px] h-[40px] flex justify-center items-center text-2xl rounded-full'>1</div>
            <div className='text-center font-bold'>Register your hospital on HealthKard</div>
            <div className='text-center'>Register with your details so users can see list the listing</div>
          </div>
          <div className='flex flex-col gap-5 items-center  justify-center w-full md:w-1/4 shadow-md border p-4 rounded-md'>
            <div className='font-bold green text-white w-[40px] h-[40px] flex justify-center items-center text-2xl rounded-full'>2</div>
            <div className='text-center font-bold'>Get <br/> Verified</div>
            <div className='text-center'>Our team will verify the details provided</div>
          </div>
          <div className='flex flex-col gap-5 items-center justify-center w-full md:w-1/4 shadow-md border p-4 rounded-md'>
            <div className='font-bold green text-white w-[40px] h-[40px] flex justify-center items-center text-2xl rounded-full'>3</div>
            <div className='text-center font-bold'>YAY!! <br/>Appear to users</div>
            <div className='text-center'>Congratulations!! you can now appear to users</div>
          </div>
        </div>
      </div>
      <div ref={aboutUsRef} className='p-3 flex md:flex-row flex-col gap-5  md:w-2/3 w-3/4 mx-auto items-center justify-around'>
        <div className='font-bold text-xl lg:hidden'>About US</div>
        <img src={aboutimg} alt='aboutimg' width='300px' className='' />
        <div className='flex flex-col gap-2 w-full items-center lg:items-start'>
          <div className='font-bold text-md lg:block hidden'>About US</div>
          <div className='text-xl font-semibold'>Pioneering accessible, affordable, and quality healthcare.</div>
          <div className=''>Revolutionizing healthcare access with affordable, hassle-free consultations. Our single card solution eliminates financial barriers, enabling regular check-ups and preventive care. Empowering individuals to prioritize well-being, we aim to create a healthier future for all. Join us in making a positive impact on healthcare accessibility. Your health, our priority.</div>
          <Link to='signup' className='blue text-white p-2 rounded-md'>Get Started</Link>
        </div>
      </div>
      <div className='flex flex-col items-center gap-10 my-10 p-2'>
        <div className='font-bold text-4xl'>Why Choose Us ?</div>
        <div className='flex flex-col gap-10 md:w-2/3 w-3/4' >
          <div className='flex flex-col md:flex-row border shadow-md w-full  gap-6 p-5 rounded-md'>
            <div className='w-full md:w-1/4 flex justify-center items-center'>
              <img src={img1} alt='img1' width='120px'/>
            </div>
            <div className='flex flex-col gap-5 w-full md:w-3/4'>
              <div className='font-bold text-center'>Reaching more patient</div>
              <div className='text-center'> By teaming up with us, you’ll be able to offer your services to a larger group of people who might not have easy access to  healthcare.</div>
            </div>
          </div>
          <div className='flex flex-col md:flex-row-reverse w-full  gap-6 p-5 rounded-md'>
            <div className='w-full md:w-1/4 flex justify-center items-center'>
              <img src={img2} alt='img2' width='120px'/>
            </div>
            <div className='flex flex-col gap-5 w-full md:w-3/4'>
              <div className='font-bold text-center'>Building a good reputation </div>
              <div className='text-center'> Giving free consultations can help you build a positive image and earn trust from potential patients.</div>
            </div>
          </div>
          <div className='flex flex-col md:flex-row border shadow-md w-full  gap-6 p-5 rounded-md'>
            <div className='w-full md:w-1/4 flex justify-center items-center'>
              <img src={img3} alt='img3' width='120px'/>
            </div>
            <div className='flex flex-col gap-5 w-full md:w-3/4'>
              <div className='font-bold text-center'>Marketing Support</div>
              <div className='text-center'> We’ll help you with promotional activities to get the word out about you and your hospital.</div>
            </div>
          </div>


          <div className='flex flex-col md:flex-row-reverse w-full  gap-6 p-5 rounded-md'>
            <div className='w-full md:w-1/4 flex justify-center items-center'>
              <img src={img6} alt='img4' width='220px'/>
            </div>
            <div className='flex flex-col gap-5 w-full md:w-3/4'>
              <div className='font-bold text-center'>Financial Support</div>
              <div className='text-center'>Loan Range from 30L to 80CR, <br /> Working Captial & CapEx Financing, <br /> Smart financing solutions with zero equality dilution.</div>
            </div>
          </div>

          
          <div className='flex flex-col md:flex-row border shadow-md w-full  gap-6 p-5 rounded-md'>
            <div className='w-full md:w-1/4 flex justify-center items-center'>
              <img src={img5} alt='img5' width='120px'/>
            </div>
            <div className='flex flex-col gap-5 w-full md:w-3/4'>
              <div className='font-bold text-center'>Easy Tracking</div>
              <div className='text-center'>Our app will make it simple for you to keep track of how many patients are coming in for consultations right at your fingertips.</div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row-reverse w-full  gap-6 p-5 rounded-md'>
            <div className='w-full md:w-1/4 flex justify-center items-center'>
              <img src={img4} alt='img4' width='120px'/>
            </div>
            <div className='flex flex-col gap-5 w-full md:w-3/4'>
              <div className='font-bold text-center'>Making a difference</div>
              <div className='text-center'>Join us in making a positive impact on the health of our country. Let’s work together to keep everyone healthy and fit!</div>
            </div>
          </div>

          
        </div>
      </div>
      {/*Contact form */}
      <div ref={contactUsRef} className='text-2xl text-center lg:text-4xl font-bold'>Contact Us</div>
      <div className='w-10/12 flex lg:flex-row flex-col justify-center items-center gap-4 my-5 mx-auto'>
        <div className='w-1/2 hidden lg:flex'>
          <img src={contactus} alt={contactus}/>
        </div>
        <div className='w-full lg:w-1/2 flex flex-col justify-center items-center'>
            <div className='w-full p-2 '>
              <div className="relative w-full min-w-[200px] h-10">
                <input onChange={(e)=>handleChangeInput(e)} value={userData.name} name='name' className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Full Name</label>
              </div>
            </div>
            <div className='w-full p-2'>
              <div className="relative w-full min-w-[200px] h-10">
                <input onChange={(e)=>handleChangeInput(e)} value={userData.hospitalName} name='hospitalName' className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Hospital Name</label>
              </div>
            </div>
            <div className='w-full p-2'>
              <div className="relative w-full min-w-[200px] h-10">
                <input onChange={(e)=>handleChangeInput(e)} value={userData.phoneNumber} name='phoneNumber' className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Mobile Number</label>
              </div>
            </div>
            <div className='w-full p-2'>
              <div className="relative w-full min-w-[200px] h-10">
                <input onChange={(e)=>handleChangeInput(e)} value={userData.email} name="email" className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Email Address</label>
              </div>
            </div>
            <div className='w-full p-2'>
              <label className='flex gap-2 flex-col'>
                <span>Enter description</span>
                <textarea onChange={(e)=>handleChangeInput(e)} value={userData.description} name='description' rows='10' className='p-2 rounded-md border border-black'></textarea>
              </label>
            </div>
            <div onClick={submit} className='w-full flex justify-center items-center blue text-white p-2 rounded-md hover:cursor-pointer '>
              Submit
            </div>
        </div>
      </div>
      <div onClick={()=>handleScroll(topRef)} className='hover:cursor-pointer fixed right-10 rounded-full shadow-xl blue text-white p-2 text-2xl z-10 bottom-10'><GoMoveToTop/></div>
      <Footer/>
    </div>
  )
}

export default HospitalCredentails