import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
// import Navbar from './components/Navbar'

function Home() {
  // const location = useLocation();
  return (
    <div className='flex flex-col'>
      <div className={`${window.location.pathname==='/'?'h-[100vh]':'h-[90vh]'} overflow-scroll`}>
        <Outlet/>
      </div>
    </div>
  )
}

export default Home