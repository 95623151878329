import React, { useEffect, useRef, useState } from 'react'
import img3 from './assets/img3.png'
import step1 from './assets/step1.gif'
import step2 from './assets/step2.gif'
import step3 from './assets/step3.gif'
import step4 from './assets/step4.gif'
import { FaCircle, FaRupeeSign } from "react-icons/fa";
import serverURL from './server-config'
import axios from 'axios'
import Footer from './components/Footer'
import { Link, Outlet } from 'react-router-dom'
import img1 from './assets/img1.png'
import img2 from './assets/img2.png'
import { TiTick } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa";
import HomeNavbar from './components/HomeNavbar'
import startupindia from './assets/backedby/startupindia.png';
import srm from './assets/backedby/srm.png';
import klu from './assets/backedby/klu.jpg';
import SISFS from './assets/backedby/dpiit.jpg';
import contactus from './assets/backedby/contactus.gif';
import { GoMoveToTop } from "react-icons/go";
import { ToastContainer, toast } from 'react-toastify'
function UserDefineRoutes() {
  const [data,setData] = useState([]);
  const aboutRef = useRef(null);
  const stepsToJoinRef = useRef(null);
  const featuresRef = useRef(null);
  const hospitalRef = useRef(null);
  const plansRef = useRef(null);
  const contactUsRef = useRef(null);
  const topRef = useRef(null);
  const [userData,setUserData] = useState({
    name:"",
    email:"",
    phoneNumber:"",
    description:""
  })
  const handleChangeInput = (e)=>{
    setUserData(prev=>({
      ...prev,
      [e.target.name]: [e.target.value]
    }))
  }
  const submit = async()=>{
    if(!userData.phoneNumber || !userData.description || !userData.name){
      toast.error("Please fill all the details");
      return;
    }
    await axios.post(`${serverURL}/sendMail`,{subject:`${userData.name} has sent a mail`,message:`${userData.description}\n Contact details :\n ${userData.email} \n ${userData.phoneNumber}`}).then((result) => {
      toast.success("Message sent successfully our team will contact soon");
      setUserData({
        name:"",
        email:"",
        phoneNumber:"",
        description:""
      })
    }).catch((err) => {
      toast.error("Some thing wents wrong");
    });
  }

  const handleScroll = (ref) => {
      if (ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth' });
      }
  }

  useEffect(()=>{
      let fetchData = async()=>{
          await axios.get(`${serverURL}/hospitals`).then((result) => {
              let data =[result.data[0],result.data[3],result.data[6]];
              setData(data);
          }).catch((err) => {
              console.log('error ',err)
          });
      }
      fetchData();
  },[])
  return (
    <div className='flex w-[100%] flex-col justify-center items-center  relative'>
      <Outlet/>
      <ToastContainer/>
      <img src={img1} alt={img1} className='w-[40px] lg:w-[150px] absolute right-0 top-[50px] lg:flex'/>
      <img src={img2} alt={img2} className='w-[40px] lg:w-[150px] absolute left-0 top-[350px] lg:flex '/>
      <div ref={topRef} className=' w-full'>
        <HomeNavbar handleScroll={handleScroll} aboutRef={aboutRef} stepsToJoinRef={stepsToJoinRef} featuresRef={featuresRef} hospitalRef={hospitalRef} plansRef={plansRef} contactUsRef={contactUsRef}/>
      </div>
      <div className='flex flex-col lg:h-[85vh] w-full my-5 justify-center items-center p-4 '>
        <div className='flex flex-col gap-6 '>
          <div className='text-xl lg:text-6xl text-blue font-bold'>
            <div className=''>Unlocking Optimal Health :</div>
            <div className=''>The Power of Healthkard</div>
          </div>
          <div className='text-green text-md lg:text-3xl font-semibold'>
            <div className='capitalize'>Unlimited hospital</div>
            <div className='capitalize'>Unlimited doctor visits</div>
            <div className='capitalize'>Unlimited Time's with validity of 28 days</div>
          </div>
          <div className='flex w-full justify-between text-xs lg:text-2xl gap-1'>
            <Link to='listOfHospitals' className='green text-white p-2 rounded-md w-5/12 lg:w-2/5 text-center hover:cursor-pointer'>Associated Hospitals</Link>
            <Link to='userRenewalFrom/newUser' className='blue text-white p-2 rounded-md w-5/12 lg:w-2/5 text-center flex justify-center items-center hover:cursor-pointer'>Get Your Kard @99/-</Link>
          </div>
        </div>
      </div>
      {/*Backed by */}
      <div className='w-full flex flex-col justify-center items-center gap-4 my-4'>
        <div className='text-xl lg:text-6xl text-blue  font-semibold capitalize'>Backed By</div>
        <div className='flex flex-wrap p-4 gap-4  justify-around w-full items-center'>
          <div className='w-[120px] lg:w-[240px]'>
            <img src={startupindia}alt={startupindia}/>
          </div>
          <div className='w-[120px] lg:w-[200px]'>
            <img src={srm}alt={srm}/>
          </div>
          <div className='w-[140px] lg:w-[240px]'>
            <img src={klu}alt={klu}/>
          </div>
          <div className='w-[120px] lg:w-[200px]'>
            <img src={SISFS} alt={SISFS}/>
          </div>
        </div>
      </div>
      {/* About us */}
      <div ref={aboutRef} className='w-full flex flex-col justify-center items-center gap-2 my-4'>
        <div className='text-xl lg:text-6xl text-blue font-semibold capitalize'>About us</div>
        <div className='flex flex-wrap p-4 gap-2  justify-around w-full items-center'>
          <div className='p-4'>
            Healthkard has a clear objective: to provide hassle-free access to physical doctor consultations. Our main agenda is to create a single card called Healthkard, that allows people to visit multiple doctors associated with us and multiple times for just INR 99. The user will get the unique Healthkard ID for identifying the personal details of the user which will prevent the misuse of the card. 
          </div>
          <div className='p-4'>
            We want to make healthcare affordable and accessible to all, so we’ve set a fixed price (INR 99) for physical Doctor consultations. This way, we remove financial barriers and ensure that everyone can receive the care they need. Healthkard also encourages regular check-ups and preventive care. Our goal is to revolutionize the way people approach healthcare by providing a convenient and cost-effective solution. We want to empower individuals to prioritize their health and seek timely medical advice. So, if you want to visit a doctor in our country, we’re here to provide you with Healthkard.
          </div>
        </div>
      </div>
      {/* Mission Vision */}
      <div className='w-full flex flex-col justify-center items-center gap-2 my-4'>
        <div className='text-xl lg:text-6xl text-blue font-semibold capitalize'>Mission</div>
        <div className='flex p-4 gap-2  justify-center w-full items-center'>
          <div className='w-full lg:w-1/2 text-center p-4 flex'>
          &#x201C; Healthkard aims to simplify healthcare by providing affordable and hassle-free access to a network of doctor consultations through a single card(Single ID) in rural and urban India.&#8221;
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col justify-center items-center gap-2 my-4'>
        <div className='text-xl lg:text-6xl text-blue font-semibold capitalize'>Vision</div>
        <div className='flex p-4 gap-2  justify-center w-full items-center'>
          <div className='w-full lg:w-1/2 text-center p-4 flex'>
          &#x201C; Our vision is to create a healthcare ecosystem where everyone can prioritize their well-being with convenient and accessible medical care.&#8221;
          </div>
        </div>
      </div>
      {/* Features */}
      <div ref={featuresRef} className='flex w-4/5  justify-center items-center flex-col gap-6'>
        <div className='text-xl lg:text-6xl text-blue  font-semibold capitalize'>Healthkard provides you </div>
        <div className='flex flex-col lg:flex-row justify-around items-center gap-4 p-2'>
          <div className=' lg:flex w-1/2'>
            <img src={img3} alt='image 3' width='350px'/>
          </div>
          <div className='w-full lg:w-2/3 text-sm  lg:text-xl flex flex-col gap-4 p-2 '>
            <div className='flex items-center gap-2 '><FaCircle className='w-1/12 text-green'/><div className='w-11/12'>Visit any of our associated hospital</div></div>
            <div className='flex items-center gap-2 '><FaCircle className='w-1/12 text-green'/><div className='w-11/12'>Free Doctor Consultation (OP)</div></div>
            <div className='flex items-center gap-2 '><FaCircle className='w-1/12 text-green'/><div className='w-11/12'>Avoid high Consultation fee</div></div>
            <div className='flex items-center gap-2 '><FaCircle className='w-1/12 text-green'/><div className='w-11/12'>Longer validity with 28 days</div></div>
            <div className='flex items-center gap-2 '><FaCircle className='w-1/12 text-green'/><div className='w-11/12'>Chance of visiting multiple doctors with single <span className='text-green'>HEALTHKARD</span> </div></div>
          </div>
        </div>
      </div>
      {/*Steps to join */}
      <div ref={stepsToJoinRef} className='flex flex-col w-full gap-5 lg:gap-12 my-12 p-5 '>
        <div className='text-xl lg:text-6xl font-semibold text-blue text-center'>Join Our Family </div>
        <div className='flex flex-col gap-4 lg:gap-12 w-full  mx-auto'>
          <div className='bg-green-200 lg:bg-white px-6 lg:px-2 py-2 text-center text-black rounded-tl-full rounded-br-full flex gap-6 w-full lg:w-2/3 mx-auto justify-around '>
            <div className='hidden lg:flex w-2/5 justify-center items-center'>
              <img src={step1} width='250px' alt='imag1'/>
            </div>
            <div className='flex flex-col lg:w-2/5 gap-4 w-full p-6'>
              <div className='text-lg lg:text-4xl text-blue font-semibold'>Step 1:</div>
              <div className='lg:text-2xl'>Fill in your details and become a part of our HealthKard family.</div>
            </div>
          </div>
          <div className='bg-green-200 lg:bg-white px-6 lg:px-2 py-2 text-center text-black rounded-tl-full rounded-br-full flex flex-row-reverse gap-6 w-full lg:w-2/3 mx-auto justify-around'>
            <div className='hidden lg:flex w-4/5 lg:w-2/5 justify-center items-center'>
              <img src={step2} width='250px' alt='imag1'/>
            </div>
            <div className='flex flex-col lg:w-2/5 gap-4 w-full p-6'>
              <div className='text-lg lg:text-4xl text-blue font-semibold'>Step 2:</div>
              <div className='lg:text-2xl'>Locate hospitals near you for added convenience.</div>
            </div>
          </div>
          <div className='bg-green-200 lg:bg-white px-6 lg:px-2 py-2 text-center text-black rounded-tl-full rounded-br-full flex gap-6 lg:w-2/3 mx-auto w-full justify-around'>
            <div className='hidden lg:flex w-2/5 justify-center items-center'>
              <img src={step3} width='250px' alt='imag1'/>
            </div>
            <div className='flex flex-col lg:w-2/5 gap-4 w-full p-6'>
              <div className='text-lg lg:text-4xl text-blue font-semibold'>Step 3:</div>
              <div className='lg:text-2xl'>Verify your identity with the receptionist upon arrival.</div>
            </div>
          </div>
          <div className='bg-green-200 lg:bg-white px-6 lg:px-2 py-2 text-center text-black rounded-tl-full rounded-br-full flex flex-row-reverse gap-6 w-full lg:w-2/3 mx-auto justify-around'>
            <div className='hidden lg:flex w-4/5 lg:w-2/5 justify-center items-center'>
              <img src={step4} width='250px' alt='imag1'/>
            </div>
            <div className='flex flex-col lg:w-2/5 gap-4 w-full p-6'>
              <div className='text-lg lg:text-4xl text-blue font-semibold'>Step 4:</div>
              <div className='lg:text-2xl'>Address your health concerns by consulting with our experienced doctors.</div>
            </div>
          </div>
        </div>
      </div>
      {/*plans */}
      <div ref={plansRef} className='text-2xl lg:text-4xl font-bold'>Healthkard Plans</div>
      <div className='w-10/12 flex lg:flex-row flex-col justify-center gap-4 my-5'>
        <div className='w-10/12 mx-auto lg:w-1/5 flex flex-col gap-5'>
          <div className='flex flex-col border border-black shadow-xl bg-white gap-2 p-4 rounded-2xl'>
            <div className='text-md lg:text-2xl font-bold'>Monthly</div>
            <div className='text-3xl lg:text-6xl font-bold flex'><FaRupeeSign className='text-sm'/>99</div>
            <ul className='text-sm lg:text-md flex flex-col gap-2 font-semibold'>
              <li className='flex items-center gap-2'><TiTick/>Unlimited Hospitals</li>
              <li className='flex items-center gap-2'><TiTick/>Unlimited Doctor Visits</li>
              <li className='flex items-center gap-2'><TiTick/>Avoid High OP Fees</li>
              <li className='flex items-center gap-2'><TiTick/>No Hidden Charges</li>
              <li className='flex items-center gap-2'><TiTick/>Validity of 28 days</li>
            </ul>
          </div>
          <Link to='userRenewalFrom/newUser' className='bg-black text-white text-md rounded-full p-2 flex items-center justify-center gap-4'>Get Monthly Plan<div className='bg-white text-black p-1 rounded-full'><FaArrowRight /></div></Link>
        </div>
        <div className='w-10/12 mx-auto lg:w-1/5 flex flex-col gap-5'>
          <div className='flex flex-col border border-black shadow-xl bg-white gap-2 p-4 rounded-2xl'>
            <div className='text-md lg:text-2xl font-bold'>Quarterly</div>
            <div className='text-3xl lg:text-6xl font-bold flex'><FaRupeeSign className='text-sm'/>297</div>
            <ul className='text-sm lg:text-md flex flex-col gap-2 font-semibold'>
              <li className='flex items-center gap-2'><TiTick/>Unlimited Hospitals</li>
              <li className='flex items-center gap-2'><TiTick/>Unlimited Doctor Visits</li>
              <li className='flex items-center gap-2'><TiTick/>Avoid High OP Fees</li>
              <li className='flex items-center gap-2'><TiTick/>No Hidden Charges</li>
              <li className='flex items-center gap-2'><TiTick/>Validity of 84 days</li>
            </ul>
          </div>
          <Link to='userRenewalFrom/newUser' className='bg-black text-white text-md rounded-full p-2 flex items-center justify-center gap-4'>Get Quarterly Plan<div className='bg-white text-black p-1 rounded-full'><FaArrowRight /></div></Link>
        </div>
        <div className='w-10/12 mx-auto lg:w-1/5 my-4 lg:my-0 flex flex-col gap-5 relative'>
          <div className='absolute -top-4 right-0 bg-violet-400 p-2 rounded-full'>#bestdeal</div>
          <div className='flex flex-col border border-black shadow-xl bg-white gap-2 p-4 rounded-2xl'>
            <div className='text-3xl lg:text-2xl font-bold'>Half Yearly</div>
            <div className='text-3xl lg:text-6xl font-bold flex'><FaRupeeSign className='text-sm'/>
              499
            </div>
            <ul className='text-sm lg:text-md flex flex-col gap-2 font-semibold'>
              <li className='flex items-center gap-2'><TiTick/>Unlimited Hospitals</li>
              <li className='flex items-center gap-2'><TiTick/>Unlimited Doctor Visits</li>
              <li className='flex items-center gap-2'><TiTick/>Avoid High OP Fees</li>
              <li className='flex items-center gap-2'><TiTick/>No Hidden Charges</li>
              <li className='flex items-center gap-2'><TiTick/>Validity of 168 days</li>
            </ul>
          </div>
          <Link to='userRenewalFrom/newUser' className='bg-black text-white text-md rounded-full p-2 flex items-center justify-center gap-4'>Get Half Yearly Plan<div className='bg-white text-black p-1 rounded-full'><FaArrowRight /></div></Link>
        </div>
        <div className='w-10/12 mx-auto lg:w-1/5 flex flex-col gap-5'>
          <div className='flex flex-col border border-black shadow-xl bg-white gap-2 p-4 rounded-2xl'>
            <div className='text-md lg:text-2xl font-bold'>Yearly</div>
            <div className='text-3xl lg:text-6xl font-bold flex'><FaRupeeSign className='text-sm'/>899</div>
            <ul className='text-sm lg:text-md flex flex-col gap-2 font-semibold'>
              <li className='flex items-center gap-2'><TiTick/>Unlimited Hospitals</li>
              <li className='flex items-center gap-2'><TiTick/>Unlimited Doctor Visits</li>
              <li className='flex items-center gap-2'><TiTick/>Avoid High OP Fees</li>
              <li className='flex items-center gap-2'><TiTick/>No Hidden Charges</li>
              <li className='flex items-center gap-2'><TiTick/>Validity of 336 days</li>
            </ul>
          </div>
          <Link to='userRenewalFrom/newUser' className='bg-black text-white text-md rounded-full p-2 flex items-center justify-center gap-4'>Get Yearly Plan<div className='bg-white text-black p-1 rounded-full'><FaArrowRight /></div></Link>
        </div>
      </div>
      {/*Hospitals */}
      <div ref={hospitalRef} className='w-4/5 flex flex-col gap-7'>
        <div className='text-blue font-semibold text-xl lg:text-6xl my-4'>We are live in your city</div>
        <div className='flex flex-col lg:flex-row gap-2 justify-around'>
          {
            data.map((hospital,index)=>{
              return(
                <div key={index} className='flex flex-col w-full lg:w-1/4 shadow-md p-2  rounded-md border'>
                  <img src={hospital.mediaDetails.hospitalImageURL} alt={hospital.mediaDetails.logoURL} width='250px' className='h-[120px]' />
                  <div className='text-blue text-lg lg:text-xl font-bold'>{hospital.hospitalDetails.hospitalTradeName}</div>
                  <div className='text-xs lg:text-sm w-full gap-2'>
                      {hospital.hospitalDetails.address.street},
                      {hospital.hospitalDetails.address.landmark},
                      {hospital.hospitalDetails.address.city},
                      {hospital.hospitalDetails.address.code}
                  </div>
                </div>
              )
            })
          }
        </div>
        <Link to='listOfHospitals' className='blue text-white font-semibold w-2/3 lg:w-1/2 mx-auto text-center my-12 p-2 rounded-md'>Check all Hospitals here</Link>
      </div>
      {/*Contact form */}
      <div ref={contactUsRef} className='text-2xl lg:text-4xl font-bold'>Contact Us</div>
      <div className='w-10/12 flex lg:flex-row flex-col justify-center items-center gap-4 my-5 '>
        <div className='w-1/2 hidden lg:flex'>
          <img src={contactus} alt={contactus}/>
        </div>
        <div className='w-full lg:w-1/2 flex flex-col justify-center items-center'>
            <div className='w-full p-2 '>
              <div className="relative w-full min-w-[200px] h-10">
                <input onChange={(e)=>handleChangeInput(e)} value={userData.name} name='name' className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Full Name</label>
              </div>
            </div>
            <div className='w-full p-2'>
              <div className="relative w-full min-w-[200px] h-10">
                <input onChange={(e)=>handleChangeInput(e)} value={userData.phoneNumber} name='phoneNumber' className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Mobile Number</label>
              </div>
            </div>
            <div className='w-full p-2'>
              <div className="relative w-full min-w-[200px] h-10">
                <input onChange={(e)=>handleChangeInput(e)} value={userData.email} name="email" className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Email Address</label>
              </div>
            </div>
            <div className='w-full p-2'>
              <label className='flex gap-2 flex-col'>
                <span>Enter description</span>
                <textarea onChange={(e)=>handleChangeInput(e)} value={userData.description} name='description' rows='10' className='p-2 rounded-md border border-black'></textarea>
              </label>
            </div>
            <div onClick={submit} className='w-full flex justify-center items-center blue text-white p-2 rounded-md hover:cursor-pointer '>
              Submit
            </div>
        </div>
      </div>
      <div onClick={()=>handleScroll(topRef)} className='hover:cursor-pointer fixed right-10 rounded-full shadow-xl blue text-white p-2 text-2xl z-10 bottom-10'><GoMoveToTop/></div>
      <Footer/>
    </div>
  )
}

export default UserDefineRoutes
