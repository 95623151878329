import React, { useState, useEffect } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import DoctorDeatailsForm from './DoctorDeatailsForm';

function Form2() {
  const [doctorList, setDoctorList] = useState(() => {
    const storedDoctorList = localStorage.getItem('doctorList');
    return storedDoctorList ? JSON.parse(storedDoctorList) : [{
      name: '',
      email: '',
      qualification: '',
      lisenceNumber: '',
      doctorLicenseURL: '',
      number: '',
    }];
  });

  // Add doctor
  const addAnotherDoctor = () => {
    const updatedList = [...doctorList, {
      name: '',
      email: '',
      qualification: '',
      lisenceNumber: '',
      doctorLicenseURL: '',
      number: '',
    }];
    setDoctorList(updatedList);
  };

  // Remove doctor
  const removeDoctor = (index) => {
    const updatedList = [...doctorList];
    updatedList.splice(index, 1);
    setDoctorList(updatedList);
  };

  useEffect(() => {
    localStorage.setItem('doctorList', JSON.stringify(doctorList));
  }, [doctorList]);

  return (
    <div className='h-full overflow-scroll noscroll lg:w-4/5 mx-auto'>
      <div className='text-2xl lg:text-4xl mt-7 font-medium'>Doctor Details</div>
      {doctorList.map((doctor, index) => (
        <DoctorDeatailsForm
          key={index}
          index={index}
          doctor={doctor}
          removeDoctor={removeDoctor}
        />
      ))}
      <div onClick={addAnotherDoctor} className='flex items-center gap-2 text-xl lg:text-2xl my-4 hover:cursor-pointer'>
        <IoIosAddCircleOutline />
        <div className=''>Add Another Doctor</div>
      </div>
    </div>
  );
}

export default Form2;
