import React, { useEffect, useState } from 'react'
import { RxCross2 } from "react-icons/rx";
import { RiImageAddLine } from "react-icons/ri";
import {ref, uploadBytesResumable, getDownloadURL, deleteObject}  from 'firebase/storage'
import {storage} from '../../firebase-config';

function Form3() {
    const [mediaDetails, setMediaDetails] = useState(() => {
        const storedMediaDetails = localStorage.getItem('mediaDetails');
        return storedMediaDetails ? JSON.parse(storedMediaDetails) : {
            logoURL:"",
            hospitalImageURL:"",
            doctorImageURL:"",
            achivements:[],
            desc:""
        }
      });   
      const [uploading,setUploading] = useState(false);
    useEffect(()=>{
        localStorage.setItem('mediaDetails',JSON.stringify(mediaDetails));
    },[mediaDetails])

    const upload = (e) => {
        setUploading(true);
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            const imageRef = ref(storage, 'Hospital/'+localStorage.getItem('hospitalId')+'/' + e.target.name + '/' + selectedFile.name);
            const uploadTask = uploadBytesResumable(imageRef, selectedFile);
            uploadTask.on('state_changed',(snapshot) => {
                },
                (error) => {
                    console.error('Error uploading image:', error);
                    setUploading(false);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((imageUrl) => {
                        if(e.target.name === 'achive1' || e.target.name === 'achive2'){
                            let temp = mediaDetails.achivements;
                            temp.push(imageUrl);
                            setMediaDetails(previous=>({
                                ...previous,
                                achivements:temp
                             }));   
                        }else{
                            setMediaDetails(previous=>({
                               ...previous,
                               [e.target.name+'URL']:imageUrl
                            }));   
                        }
                        setUploading(false);
		            });
                }
            );
        } else {
            alert('Please select an image to upload.');
            setUploading(false);
        }
    };
    
    const deleteFile = (filePath,type) => {
        const fileRef = ref(storage, filePath);
        deleteObject(fileRef).then((result) => {
        if(type==='achivements'){
            let temp = mediaDetails.achivements;
            temp[0] === filePath ? temp[0] ='':temp[1]='';
            setMediaDetails(previous=>({
                ...previous,
                achivements:temp
            }));
        }else{
            setMediaDetails(previous=>({
                ...previous,
                [type]:''
            }));
        }
        
        }).catch((err) => {
            console.error('Error deleting file:', err);
        });        
    };
  return (
    <div className='relative h-full lg:w-4/5  mx-auto'>
        {!uploading && <div className='h-full overflow-scroll noscroll  flex flex-col items-center '>
            <div className='text-2xl lg:text-4xl mt-7 font-medium w-full '>Media Details</div>
            <div className='flex flex-col gap-4 mt-7 w-full shadow-md p-8'>
                <div className='text-gray-400 text-center text-xl'>Hospital Logo will be displayed during searching and locating<span className='font-bold text-red-600'>*</span></div>
                {!mediaDetails.logoURL && <label htmlFor="logo" className="relative hover:cursor-pointer blue text-white h-10 flex justify-center items-center rounded-md">
                    <span>Upload Logo</span>
                    <input type="file" id="logo" name='logo' className="hidden" onChange={upload}  accept="image/*"/>
                </label>}
                {mediaDetails.logoURL && <div className=' flex items-center bg-gray-400 w-fit p-1 rounded-md justify-center gap-2 text-white'><a rel="noopener noreferrer" href={mediaDetails.logoURL} target='_blank' className=' flex items-center'>Click here to see the image</a><div className='hover:cursor-pointer hover:text-red-400'><RxCross2 onClick={()=>deleteFile(mediaDetails.logoURL,'logoURL')} className=''/></div> </div>}
                <div className='text-gray-400 text-center mt-4 text-xl'>Hospital image will be used as a thumbnail in the banner<span className='font-bold text-red-600'>*</span></div>
                {!mediaDetails.hospitalImageURL && <label htmlFor="hospitalImage" className="relative hover:cursor-pointer blue text-white h-10 flex justify-center items-center rounded-md">
                    <span>Upload Hospital Image</span>
                    <input type="file" id="hospitalImage" name='hospitalImage' className="hidden" onChange={upload}  accept="image/*"/>
                </label>}
                {mediaDetails.hospitalImageURL && <div className=' flex items-center bg-gray-400 w-fit p-1 rounded-md justify-center gap-2 text-white'><a rel="noopener noreferrer" href={mediaDetails.hospitalImageURL} target='_blank' className=' flex items-center'>Click here to see the image</a><div className='hover:cursor-pointer hover:text-red-400'><RxCross2 onClick={()=>deleteFile(mediaDetails.hospitalImageURL,'hospitalImageURL')} className=''/></div> </div>}
                <div className='text-gray-400 text-center mt-4 text-xl'>Doctor photo will be displayed in the description (Optional)</div>
                {!mediaDetails.doctorImageURL && <label htmlFor="doctorImage" className="relative hover:cursor-pointer blue text-white h-10 flex justify-center items-center rounded-md">
                    <span>Upload Doctor Image</span>
                    <input type="file" id="doctorImage" name='doctorImage' className="hidden" onChange={upload}  accept="image/*"/>
                </label>}
                {mediaDetails.doctorImageURL && <div className=' flex items-center bg-gray-400 w-fit p-1 rounded-md justify-center gap-2 text-white'><a rel="noopener noreferrer" href={mediaDetails.doctorImageURL} target='_blank' className=' flex items-center'>Click here to see the image</a><div className='hover:cursor-pointer hover:text-red-400'><RxCross2 onClick={()=>deleteFile(mediaDetails.doctorImageURL,'doctorImageURL')} className=''/></div> </div>}
                <div className='text-gray-400 text-center mt-4 text-xl'>Add two more photos to show public (Optional)</div>
                <div className='flex gap-4 items-center'>
                    {!mediaDetails.achivements[0] && <label htmlFor='achive1' className='w-1/5 hover:cursor-pointer border border-black shadow-md flex flex-col gap-2 items-center justify-center p-2 rounded-md'>
                        <RiImageAddLine className='text-4xl'/>
                        <div className=''>Add Image</div>
                        <input className='hidden' id='achive1' name='achive1' type='file' accept='image/*'  onChange={upload}/>
                    </label>}
                    {!mediaDetails.achivements[1] && <label htmlFor='achive2' className='w-1/5 hover:cursor-pointer border border-black shadow-md flex flex-col gap-2 items-center justify-center p-2 rounded-md'>
                        <RiImageAddLine className='text-4xl'/>
                        <div className=''>Add Image</div>
                        <input className='hidden' id='achive2' name='achive2' type='file' accept='image/*'  onChange={upload}/>
                    </label>}
                    {mediaDetails.achivements[0] && <div className=' flex items-center bg-gray-400 w-fit p-1 rounded-md justify-center gap-2 text-white'><a rel="noopener noreferrer" href={mediaDetails.achivements[0]} target='_blank' className=' flex items-center'>Click here to see the image</a><div className='hover:cursor-pointer hover:text-red-400'><RxCross2 onClick={()=>deleteFile(mediaDetails.achivements[0],'achivements')} className=''/></div> </div>}
                    {mediaDetails.achivements[1] && <div className=' flex items-center bg-gray-400 w-fit p-1 rounded-md justify-center gap-2 text-white'><a rel="noopener noreferrer" href={mediaDetails.achivements[1]} target='_blank' className=' flex items-center'>Click here to see the image</a><div className='hover:cursor-pointer hover:text-red-400'><RxCross2 onClick={()=>deleteFile(mediaDetails.achivements[1],'achivements')} className=''/></div> </div>}
                </div>
                <div className='text-gray-400 text-center mt-4 text-xl'>Write 100 words about your hospital<span className='font-bold text-red-600'>*</span></div>
                <textarea value={mediaDetails.desc} className='border mt-4 p-4' placeholder='Give a brief description about your hospital in 4 lines '  rows={5} onChange={(e)=>setMediaDetails({...mediaDetails,desc:e.target.value})}></textarea> 
            </div>
        </div>}
        {uploading && <div className=''>Please wait while uploading</div>}
    </div>
  )
}

export default Form3