import axios from 'axios';
import serverURL from '../../server-config'
import React, { useEffect, useState } from 'react'
import SplashScreen from './SplashScreen';

function HospitailDoctorDetails() {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(true);
  useEffect(()=>{
    setLoading(true);
    const hospitalId = localStorage.getItem('hospitalId');
    let fetchData = async()=>{
      const response = await axios.get(`${serverURL}/getDoctorDetails/${hospitalId}`);
      setData(response.data);
      setLoading(false);
    }
    fetchData();
  },[])
  return (
    <div>
        {
            loading?(<SplashScreen/>):(
                <div className='overflow-scroll h-[75vh]'>
                    {data.map((doctorDetails,index)=>{
                        return(
                            <div key={index} className='mb-12 '>
                                <div className='text-2xl my-3 font-semibold'>Doctor {index+1} Details</div>
                                <div className='w-full flex light-green rounded-md p-3 hover:font-bold hover:cursor-pointer'>
                                    <div className='w-1/2 font-semibold'>Name</div>
                                    <div className='w-1/2'>{doctorDetails.name}</div>
                                </div>
                                <div className='w-full flex  rounded-md p-3 hover:font-bold hover:cursor-pointer'>
                                    <div className='w-1/2 font-semibold'>Email</div>
                                    <div className='w-1/2'>{doctorDetails.email}</div>
                                </div>
                                <div className='w-full flex light-green rounded-md p-3 hover:font-bold hover:cursor-pointer'>
                                    <div className='w-1/2 font-semibold'>Qualification</div>
                                    <div className='w-1/2'>{doctorDetails.qualification}</div>
                                </div>
                                <div className='w-full flex  rounded-md p-3 hover:font-bold hover:cursor-pointer'>
                                    <div className='w-1/2 font-semibold'>Contact Number</div>
                                    <div className='w-1/2'>+{doctorDetails.number}</div>
                                </div>
                                <div className='w-full flex light-green rounded-md p-3 hover:font-bold hover:cursor-pointer'>
                                    <div className='w-1/2 font-semibold'>License Number</div>
                                    <div className='w-1/2'>{doctorDetails.lisenceNumber}</div>
                                </div>
                                <div className='w-full flex  p-3 rounded-md hover:font-bold hover:cursor-pointer'>
                                    <div className='w-1/2 font-semibold'>License File</div>
                                    <a rel="noopener noreferrer" href={doctorDetails.doctorLicenseURL} target='_blank'  className=' blue text-white w-fit px-2 rounded'> View </a>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }
    </div>
  )
}

export default HospitailDoctorDetails