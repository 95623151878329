import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import serverURL from '../../server-config';
import { ToastContainer, toast } from 'react-toastify';
import { HiCheckBadge } from "react-icons/hi2";
import logo from '../../assets/logo.svg'
import { FaMapMarkerAlt } from "react-icons/fa";
import Footer from '../../components/Footer';
function HospitalUserDetails() {
    const [hospital,setHospital] = useState({});
    const [loading,setLoading] = useState(true);
    const {hospitalId} = useParams();
    useEffect(()=>{
        let fetchData = async()=>{
            await axios.get(`${serverURL}/getHospital/${hospitalId}`).then((result) => {
                setHospital(result.data);
                setLoading(false);
            }).catch((err) => {
                toast.error("Error while fecthing data");
            });
        }
        fetchData();
    },[hospitalId]);
  return (
    <div className='flex flex-col justify-between'>
        <Navbar/>
        <ToastContainer/>
        <div className=' overflow-scroll'>
            {!loading && hospital.hospitalDetails && 
                <div className='p-4 mx-auto lg:h-full w-10/12 lg:w-4/5 flex flex-col gap-2 lg:gap-5 lg:justify-center lg:items-center'>
                    <div className='flex lg:flex-row flex-col gap-4'>
                        <div className='w-full lg:w-1/3 flex justify-center items-center '>
                            <img className='w-full rounded-md max-h-[200px]' src={hospital.mediaDetails.hospitalImageURL} alt='hospitalImage'/>
                        </div>
                        <div className='flex flex-col gap-4 w-full lg:w-2/3 '>
                            <div className='text-3xl font-bold text-blue'>{hospital.hospitalDetails.hospitalLegalName}</div>
                            <div className=''>{hospital.mediaDetails.desc}</div>
                            <div className='flex flex-col gap-2'>
                                <div className='text-gray-400 font-semibold text-2xl'>Specialized in </div>
                                {
                                    hospital.hospitalDetails.servicesOffered.map((service,index)=>{
                                        return(
                                            <div key={index} className='ml-6 text-xl flex items-center gap-4'><HiCheckBadge className='text-green'/>{service}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-col lg:flex-row gap-4'>
                        <div className='w-full lg:w-1/3 border p-4 rounded-md shadow-md flex justify-center items-center flex-col gap-3'>
                            <div className='text-2xl font-bold text-blue'>Contact Information</div>
                            <div className=''>{hospital.hospitalDetails.address.street}, {hospital.hospitalDetails.address.landmark}, {hospital.hospitalDetails.address.city}, {hospital.hospitalDetails.address.state}, {hospital.hospitalDetails.address.code}, {hospital.hospitalDetails.address.country}</div>
                            <div className='flex items-center gap-2 w-full text-sm lg:text-md'>
                                <div className='font-semibold text-green'>Contact : </div>
                                <div className=''> {hospital.hospitalDetails.hospitalNumber}</div>
                            </div>
                            <div className='flex items-center gap-1 lg:gap-2 w-full text-sm lg:text-md'>
                                <div className='font-semibold text-green'>Email :</div>
                                <div className=''> {hospital.email}</div>
                            </div>
                            <a href={` https://www.google.com/maps/search/?api=1&query=${hospital.hospitalDetails.address.lat},${hospital.hospitalDetails.address.lng}`} target='_blank' rel='noreferrer' className='blue text-white p-2 rounded-md flex gap-2 items-center hover:cursor-pointer'>Find in maps<FaMapMarkerAlt className='text-red-500' /></a>
                        </div>
                        <div className='w-full lg:w-2/3 flex flex-col gap-2'>
                            <div className='text-gray-400 text-2xl font-semibold '>Meet our cheif doctor</div>
                            <div className='flex flex-col lg:flex-row border p-2 lg:p-4 rounded-md shadow-md gap-4 justify-center items-center'>
                                <div className=' w-[200px] h-[200px] flex justify-center items-center p-2'><img className='' src={hospital.mediaDetails.doctorImageURL?hospital.mediaDetails.doctorImageURL:logo} alt='doctorimage'/></div>
                                <div className='flex flex-col w-full lg:w-1/2 p-5'>
                                    <div className='font-bold text-blue text-2xl '>{hospital.doctorList[0].name}</div>
                                    <div className='font-bold '>{hospital.doctorList[0].qualification}</div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>}
            {loading && <div className=' justify-center items-center flex h-[80vh] flex-col gap-4'>
                <img src={logo} alt='logo' width='150px'/>             
                <span class="loader1">HEALTHKARD</span>
            </div>}
            <Footer/>
        </div>
    </div>
  )
}

export default HospitalUserDetails