import React, { useEffect, useState } from 'react'
import Table from './Table'
import { IoMdPersonAdd } from "react-icons/io";
import { Link, Outlet, useParams } from 'react-router-dom';
import axios from 'axios'
import serverURL from '../../server-config';
import AgentAddForm from './AgentAddForm';
import { IoIosRefresh } from "react-icons/io";

function Agents() {
    const {agentID} = useParams();
    const [data,setData] = useState([]);
    const [filteredData,setFilteredData] = useState([]);
    const [reducer,setReducer] = useState(0);
    const [loading,setLoading] = useState(false);
    useEffect(()=>{
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${serverURL}/agents`);
                if(response.data){
                    setData(response.data.reverse());
                    setFilteredData(response.data);
                    setLoading(false)
                }
            } catch (error) {
                console.error('Error fetching agent data:', error);
            }
        };

        fetchData();
    },[reducer]);
    const filter = (term)=>{
        const newData = data.filter(row=>{
            return (row.agentID.toLowerCase().includes(term.toLowerCase())||
            row.name.toLowerCase().includes(term.toLowerCase()));
        })
        setFilteredData(newData);
    }
    const [resetPopup,setResetPopup] = useState(false);
    let resetAgent = async ()=>{
        await axios.post(`${serverURL}/reset-todays-count`).then((result) => {
            setResetPopup(false);
        }).catch((err) => {
            console.log("error while reseting",err)
        });
    }
  return (
    <div className='flex  gap-12 justify-center  p-4'>
        {resetPopup && <div className='fixed top-0 left-0 h-[100vh] w-full flex justify-center items-center b-trans z-10'>
            <div className='p-6 rounded-md flex gap-4 flex-col bg-white'>
                <div className='text-black'>Are you sure to Reset all the agents?</div>
                <div className='flex w-full justify-between'>
                    <div onClick={()=>setResetPopup(!resetPopup)} className='hover:cursor-pointer w-1/3 text-center red text-white p-2 rounded-md'>Cancel</div>
                    <div onClick={resetAgent} className='hover:cursor-pointer w-1/3 text-center blue text-white p-2 rounded-md'>Sure</div>
                </div>
            </div>
        </div>}
        <div className='flex flex-col w-1/4 gap-5'>
            <Link to='addAgent' className='green p-2 text-white items-center justify-center gap-3 text-xl hover:cursor-pointer rounded-md flex'>
                <IoMdPersonAdd />
                <div className=''>Add Agent</div>
            </Link>
            <div onClick={()=>setResetPopup(!resetPopup)} className='blue p-2 text-white items-center justify-center gap-3 text-md hover:cursor-pointer rounded-md flex'>
                Reset today's count
            </div>
            <div className=''>
                <input onChange={(e)=>filter(e.target.value)} type='search'  placeholder='Search with Agent ID or email ' className='border border-gray-100 shadow-lg w-full p-2 rounded-md'/>
            </div>
            <div onClick={()=>setReducer(prev=>prev+1)} className='flex items-center p-2 gap-2 rounded-md border self-end w-fit hover:cursor-pointer'><IoIosRefresh/>Refresh</div>
            {loading?<div className=''>Loading...</div>:<Table data={filteredData} dataOf={'Agent'}/>}
        </div>
        {agentID && <div className='w-2/3 flex items-center justify-center h-[80vh] overflow-scroll '>
            <Outlet/>
        </div>}
        {!agentID && <div className='w-2/3 flex items-center justify-center h-[80vh] overflow-scroll '>
            <AgentAddForm/>
        </div>}
    </div>
  )
}

export default Agents