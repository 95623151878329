import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FaAngleRight } from "react-icons/fa";
import serverURL from '../../../server-config';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

function HospitalsOnboarded() {
    const {agentID} = useParams();
    const [hospitals, setHospitals] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        const storedAgentID = localStorage.getItem("agentID");
        if(!agentID || !storedAgentID || agentID!==storedAgentID){
            navigate('/agentLogin');
        }else{
            const fetchData = async()=>{
                try{
                    let result = await axios.get(`${serverURL}/agents/${agentID}`);
                    setHospitals(result.data.hospitalsAdded);
                }catch(error){
                    toast.error("Something wents wrong");
                }
            }
            fetchData();
        }
    },[agentID])
  return (
    <div>
      <ToastContainer/>
      {
        hospitals.map((hospital, index)=><Hospital key={index} hospitalId={hospital.hospitalId}/>)
      }
    </div>
  )
}

export default HospitalsOnboarded

function Hospital({hospitalId}){
    const [hospital, setHospital] = useState({});
    useEffect(()=>{
        const fetchData = async()=>{
            try{
                let result = await axios.get(`${serverURL}/getHospital/${hospitalId}`);
                setHospital(result.data);
                console.log(result.data)
            }catch(error){
                toast.error("Something wents wrong");
            }
        }
        fetchData();
    },[hospitalId])

    if(!hospital.mediaDetails)return null;
    return(
        <div className='w-full lg:w-4/5  mx-auto flex  border shadow-md rounded-lg p-2 items-center gap-2'>
            <div className='w-1/5 lg:w-1/6 flex justify-center items-center'>
                <img src={hospital.mediaDetails.logoURL} className='mx-auto lg:mx-0 w-[50px] md:w-[100px] h-[50px] md:h-[100px] rounded-md shadow-md' alt='logo'/>
            </div>
            <div className='p-2 flex flex-col w-10/12 lg:w-4/5'>
                <div className='text-md lg:text-xl text-blue font-bold'>{hospital.hospitalDetails.hospitalTradeName}</div>
                <div className='text-xs lg:text-sm w-full gap-2'>{hospital.hospitalDetails.address.street},
                {hospital.hospitalDetails.address.landmark},<br className='md:hidden'/>
                {hospital.hospitalDetails.address.city},
                {hospital.hospitalDetails.address.code},<br className='md:hidden'/>
                {hospital.hospitalDetails.address.state},
                {hospital.hospitalDetails.address.country} </div>
            </div>
            <Link to={`/hospital/${hospital.hospitalId}`} className=' p-2 hover:bg-gray-200 rounded-full hover:cursor-pointer'><FaAngleRight/></Link>
        </div>
    )
}