import React, { useEffect, useState } from 'react'
import AgentTable from './AgentTable'

function AgentAddedTable({hospitalsAdded, usersAdded}) {  
    const [dataToDisplay,setDataToDisplay] = useState('hospitals');
    const [data,setData] = useState(hospitalsAdded);
    useEffect(() => {
        if (dataToDisplay === 'hospitals') {
            document.getElementById('hospitalBtn').classList.add('border-b-blue');
            document.getElementById('userBtn').classList.remove('border-b-blue');
            setData(hospitalsAdded);
        } else {
            document.getElementById('userBtn').classList.add('border-b-blue');
            document.getElementById('hospitalBtn').classList.remove('border-b-blue');
            setData(usersAdded)
        }
    }, [dataToDisplay,hospitalsAdded, usersAdded]);
  return (
    <div className='h-1/2 p-7 w-9/12 flex flex-col items-center gap-5'>
        <div className='justify-center flex gap-10 font-bold'>
            <div id='hospitalBtn' onClick={()=>setDataToDisplay('hospitals')} className='px-2 hover:cursor-pointer'>Hospitals</div>
            <div id='userBtn' onClick={()=>setDataToDisplay('users')} className='px-2 hover:cursor-pointer'>Users</div>
        </div>
        <div className='h-full w-full overflow-scroll'>
            <AgentTable data ={data} />
        </div>
    </div>
  )
}

export default AgentAddedTable