import React,{useEffect, useState} from 'react'
import logo from '../../assets/logo.svg'
import { IoIosShareAlt } from "react-icons/io";
import { FaDownload } from "react-icons/fa6";
import axios from 'axios';
import serverURL from '../../server-config';
import { useParams } from 'react-router-dom';
function AgentDownloadCard() {
    const [data,setData] = useState({});
    const {agentID} = useParams();
    useEffect(()=>{
        console.log(`${serverURL}/getAgentDetails/${agentID}`)
        axios.get(`${serverURL}/getAgentDetails/${agentID}`).then((result) => {
            setData(result.data);
            console.log(result.data)
        }).catch((err) => {
            
        });
    },[]);
  return (
    <div className='w-full items-center justify-center flex flex-col gap-6'>
        <div className='p-4  w-9/12 flex items-center justify-between light-green rounded-md'>
            <div className='w-1/3 flex justify-center items-center h-full flex-col'>
                <img src={logo} alt={logo} width='120px'/>
                <div className='font-bold text-2xl'>HealthKard Agent</div>
            </div>
            <div className='w-3/5 flex justify-center h-full flex-col gap-2'>
                <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Id</div>{data.agentID}</div>
                <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Name </div>{data.name}</div>
                <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Email </div>{data.email}</div>
                <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Password </div>{data.password}</div>
                <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Number </div>{data.number}</div>
                <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Joined in </div>{data.createDate}</div>
            </div>
        </div>
        <div className=' flex  w-9/12 justify-around '>
            <div className='green p-2 text-white w-1/6 flex justify-around rounded-md hover:cursor-pointer items-center '>Download<FaDownload /></div>
            <div className='blue p-2 text-white w-1/6 flex justify-around rounded-md hover:cursor-pointer items-center '>Share<IoIosShareAlt/></div>
        </div>
    </div>
  )     
}

export default AgentDownloadCard