import React from 'react'
import logo from './assets/logo.svg'
import { Link } from 'react-router-dom'
function Logo() {
  return (
    <Link to='/' className='flex items-center justify-between gap-2'>
      <img src={logo} alt={logo} width='42px' className='aspect-square'/>
      <div className='text-white text-xl font-bold'>Healthkard</div>
    </Link>
  )
}

export default Logo