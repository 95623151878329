import React,{useEffect, useState} from 'react'
import logo from '../../assets/logo.svg'
import Table from '../components/Table';
import UserInfo from '../components/UserInfo';
import Graph from '../components/Graph';
import axios from 'axios';
import serverURL from '../../server-config'
import { Outlet, useParams } from 'react-router-dom';
import {ToastContainer,toast} from 'react-toastify'
function Dashboard() {
    const [data,setData] = useState([]);
    const [searchedWord,setSearchedWord] = useState('');
    const columns = ['Health Id','Name','Gender','Age','phone Number'];
    const {healthId} = useParams();
    useEffect(()=>{
        const hospitalId = localStorage.getItem('hospitalId');
        let fetchData = async ()=>{
            await axios.get(`${serverURL}/getUsers/${hospitalId}`).then((result) => {
                setData(result.data.reverse()); 
            })
        }
        fetchData();
    },[]);
    const add = async () => {
        const hospitalId = localStorage.getItem('hospitalId');
        try {
          const result = await axios.get(`${serverURL}/getUser/${searchedWord}`);
          if (result.data) {
            toast.success("Record found");
            const userExists = data.some(user => user.healthId === result.data.healthId);
            console.log(result.data)
            if (!userExists) {
              await axios.post(`${serverURL}/addUserToHospital/${hospitalId}`, {user: result.data});
              setData(prevData => [...prevData, result.data]); 
            } else {
              toast.error("User already exists");
            }
          } else {
            toast.error("User not found");
          }
        } catch (error) {
          toast.error("Error searching for user");
          console.error("Error searching for user:", error);
        }
      };
        
  return (
    <>
        <ToastContainer/>
        <div className='lg:w-1/2 flex gap-2 items-center '>   
            <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input onChange={(e)=>setSearchedWord(e.target.value)} type="search" id="default-search" className="block w-full p-2 px-5 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-0 " placeholder="Search with HealthKard ID or Phone Number" required/>
            </div>
            <div onClick={add} className='blue p-2 text-white rounded-md hover:cursor-pointer text-xs lg:text-xl'>Search and Add</div>
        </div>
        <div className='flex w-full  h-full justify-between gap-2'>
            <Table data = {data} columns = {columns}/>
            <div className='fixed left-10 lg:relative lg:left-0 lg:flex flex-col w-4/5 lg:w-2/5 gap-5  '>
                <Outlet/>
                {!healthId && <div className='hidden lg:flex flex-col items-center justify-center'> 
                    <img src={logo} width='200px' alt='logo' />
                    <div className='text-2xl font-bold text-green '>Health Kard</div>
                </div>}
                <Graph />
            </div>
        </div>
    </>
  )
}

export default Dashboard