import axios from 'axios';
import React, {useState } from 'react';
import serverURL from '../../server-config';
import { ToastContainer,toast } from 'react-toastify';

function CardList() {
    const [user, setUser] = useState({});
    const [fetched,setFetched] = useState(false);
    const [healthId,setHealthId] = useState("")
    const [number,setNumber] = useState("")
    const [searchBy,setSearchBy] = useState("id");
    const fetchData = async (type) => {
        setFetched(false);
        setSearchBy(type);
        try {
            let result;
            if(type==="id"){
                result = await axios.get(`${serverURL}/getUser/${healthId}`);
            }else{
                result = await axios.get(`${serverURL}/getUserByNumber/91${number}`);
            }
            setUser(result.data);
            if(type==="id" && !result.data.image){
                setFetched(false);
                toast.error("User not found");
                return;
            }
            if(type==="number" && result.data.length===0){
                setFetched(false);
                toast.error("User not found");
                return;
            }
            toast.success("User found");
            setFetched(true);
        } catch (err) {
            toast.error("User not found")
            console.error("Error fetching users:", err);
            setFetched(false);
        }
    };

    return (
        <div className='w-full flex flex-col gap-4'>
            <ToastContainer/>
            <div className='w-full lg:w-2/3 flex gap-2 items-center mx-auto'>
                <input onChange={(e)=>setHealthId(e.target.value)} type='search' placeholder='Enter your healthkard ID' className='p-2 border border-black rounded-md w-2/3'/>
                <div onClick={()=>fetchData("id")} className='w-1/3 p-2 blue text-center text-white rounded-md hover:cursor-pointer'>Get Kard</div>
            </div>
            <div className='w-full text-center'>-- (or) --</div>
            <div className='w-full lg:w-2/3 flex gap-2 items-center mx-auto'>
                <input onChange={(e)=>setNumber(e.target.value)} type='search' placeholder='Enter your Mobile Number' className='p-2 border border-black rounded-md w-2/3'/>
                <div onClick={()=>fetchData("number")} className='w-1/3 p-2 blue text-center text-white rounded-md hover:cursor-pointer'>Get Kard</div>
            </div>
            {fetched && searchBy==="id" && 
                <div className='light-green  flex lg:flex-row flex-col gap-4 items-center p-4 w-full lg:w-2/3 mx-auto rounded-md'>
                <div className=''>
                    <img src={user.image} className='h-[200px] w-[200px] rounded-full' alt="pic"/>
                </div>
                <div className='flex flex-col'>
                    <div className='flex gap-2'>
                        <div className='font-semibold'>
                            Name :
                        </div>
                        <div className=''>
                            {user.name}
                        </div>
                    </div>
                    <div className='flex gap-2'>
                        <div className='font-semibold'>
                            HealthID :
                        </div>
                        <div className=''>
                            {user.healthId}
                        </div>
                    </div>
                    <div className=''>
                        <div className='flex gap-2'>
                            <div className='font-semibold'>
                                Gender :
                            </div>
                            {user.gender}
                        </div>
                        <div className='flex gap-2'>
                            <div className='font-semibold'>
                                Age :
                            </div>
                            {user.age}
                        </div>
                    </div>
                    <div className='flex gap-2'>
                        <div className='font-semibold'>
                            Mobile Number :
                        </div>
                        {user.number}
                        
                    </div>
                    {user.email && <div className='flex gap-2'>
                        <div className='font-semibold'>
                            Email :
                        </div>
                        {user.email}</div>}
                    <div className='flex gap-2'>
                        <div className='font-semibold'>
                            Address:
                        </div>
                        <div className=''>
                            {user.address}, {user.city}, {user.pincode}
                        </div>
                    </div>
                    <div className='flex gap-2'>
                        <div className='font-semibold'>
                            Expires by : 
                        </div>
                        {user.paymentStatus?(new Date(user.expireDate)).toLocaleDateString():"INVALID"}
                    </div>
                </div>
            </div>}
            {fetched && searchBy==="number" &&  user.map((u,index)=>{
                return(
                    <div key={index} className='light-green  flex lg:flex-row flex-col gap-4 items-center p-4 w-full lg:w-2/3 mx-auto rounded-md'>
                        <div className=''>
                            <img src={u.image} className='h-[200px] w-[200px] rounded-full' alt="pic"/>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex gap-2'>
                                <div className='font-semibold'>
                                    Name :
                                </div>
                                <div className=''>
                                    {u.name}
                                </div>
                            </div>
                            <div className='flex gap-2'>
                                <div className='font-semibold'>
                                    HealthID :
                                </div>
                                <div className=''>
                                    {u.healthId}
                                </div>
                            </div>
                            <div className=''>
                                <div className='flex gap-2'>
                                    <div className='font-semibold'>
                                        Gender :
                                    </div>
                                    {u.gender}
                                </div>
                                <div className='flex gap-2'>
                                    <div className='font-semibold'>
                                        Age :
                                    </div>
                                    {u.age}
                                </div>
                            </div>
                            <div className='flex gap-2'>
                                <div className='font-semibold'>
                                    Mobile Number :
                                </div>
                                {u.number}
                                
                            </div>
                            {user.email && <div className='flex gap-2'>
                                <div className='font-semibold'>
                                    Email :
                                </div>
                                {u.email}</div>}
                            <div className='flex gap-2'>
                                <div className='font-semibold'>
                                    Address:
                                </div>
                                <div className=''>
                                    {u.address}, {u.city}, {u.pincode}
                                </div>
                            </div>
                            <div className='flex gap-2'>
                                <div className='font-semibold'>
                                    Expires by : 
                                </div>
                                {(new Date(u.expireDate)).toLocaleDateString()}
                            </div>
                        </div>
                    </div>
                )
            })}
            {!fetched && <div className=''>
                You can get you kard here
            </div>}
        </div>
    );
}

export default CardList;
