import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import serverURL from '../../../server-config'
import { toast } from 'react-toastify';
import logo from '../../../assets/logo.svg'
function UsersOnboarded() {
    const {agentID} = useParams();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    useEffect(()=>{
        const storedAgentID = localStorage.getItem("agentID");
        if(!agentID || !storedAgentID){
            navigate('/agentLogin');
        }else{
            const fetchData = async()=>{
                try{
                    let result = await axios.get(`${serverURL}/agents/${agentID}`);
                    setUsers(result.data.usersAdded);
                }catch(error){
                    toast.error("Something wents wrong");
                }
            }
            fetchData();
        }
    },[agentID])
  return (
    <div className='flex flex-row flex-wrap gap-2 p-4 items-center justify-center'>
        {
            users.map((user, index)=><UserInfo key={index} healthId={user.healthID}/>)
        }
    </div>
  )
}

export default UsersOnboarded


function UserInfo({healthId}){
    const [data, setData] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${serverURL}/getuserDetails/${healthId}`);
                setData(result.data);
                console.log(result.data);
                } catch (error) {
            } 
        };
        fetchData();
    }, [healthId]);
    if(data.email==='not found')return null;
    return(
        <div className='flex gap-2 items-center lg:w-1/3 p-2 rounded-md shadow-md w-full light-green'>
            <div className='w-12 h-12 rounded-full'>
                <img  src={data.image} alt='userimage'/>
            </div>
            <div className=''>
                <div className='flex gap-2 items-center text-xs'>
                    <img src={logo} alt='logo' className='w-3 h-2'/>
                    {healthId}
                </div>
                <div className='text-md'>{data.name}</div>
                <div className='text-blue text-xs'>Valid till : {(new Date(data.expireDate)).toLocaleDateString()}</div>
            </div>
        </div>
    )
}
