import React, { useEffect, useState } from 'react'
import AgentCard from './AgentCard'
import AgentAddedTable from './AgentAddedTable'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import serverURL from '../../server-config';
function AgentInfo() {
    const [loading,setLoading] = useState(true);
    const {agentID} = useParams();
    const [data,setData] = useState({});
    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get(`${serverURL}/getAgentDetails/${agentID}`);
                setData(response.data); 
                setLoading(false);
            } catch (error) {
                console.error('Error fetching agent data:', error);
            }
        };  

        fetchData();
    },[agentID,agentID]);
    return (
        <div className='h-full w-full'>
            {loading ? ( 
                <p>Loading...</p>
            ) : (
                <div className='flex flex-col h-full w-full justify-center items-center gap-2'>
                    <AgentCard data={data} />
                    {data.usersAdded && <AgentAddedTable usersAdded={data.usersAdded} hospitalsAdded={data.hospitalsAdded} />}
                    <div className='w-full flex justify-center gap-4'>
                        <div className='font-bold'>Today Count : {data.todaysCount}</div>
                        <div className='font-bold'>Total Count : {data.totalCount}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AgentInfo