import React from 'react'
import serverURL from '../../server-config'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
function DeleteAgentPopup() {
    const {agentID} = useParams();
    const navigate = useNavigate();
    const deleteAgent =async()=>{
        await axios.delete(`${serverURL}/agent/${agentID}`).then((result) => {
            navigate('../../');
        }).catch((err) => {
            toast.error("Error while deleting");
        });
    }
  return (
    <div className='w-[100vw] h-[100vh] fixed top-0 left-0 b-trans flex justify-center items-center'>
        <ToastContainer/>
        <div className='bg-white text-black p-4 rounded-md flex items-center flex-col gap-4'>
            <div className='text-2xl font-semibold'>Are you sure to delete <span className='text-green'>{agentID} </span>?</div>
            <div className='flex w-full justify-between'>
                <div onClick={()=>navigate('../')} className= 'hover:cursor-pointer bg-neutral-400 text-white p-2 rounded-md'>Cancel</div>
                <div onClick={deleteAgent} className='hover:cursor-pointer red text-white p-2 rounded-md'>Yes Delete</div>
            </div>
        </div>
    </div>
  )
}

export default DeleteAgentPopup