import axios from 'axios';
import serverURL from '../../server-config';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function AgentLogin() {
  const navigate = useNavigate();
  const [data,setData] = useState({
    email:"",
    password:"",
  })
  useEffect(()=>{
    if(localStorage.getItem('agentID')){
      navigate(`/newUser/${localStorage.getItem('agentID')}`)
    }
  },[]);
  const useInputHandler = (e)=>{
      setData(prevUserData => ({
        ...prevUserData,
        [e.target.name] : e.target.value
    }));
  }
  const login = ()=>{
    if(!data.email){
      toast.error("Please enter email");
      return;
    }else if(!data.password){
      toast.error("Please enter password")
      return;
    }
    axios.get(`${serverURL}/getAgentPassword/${data.email}`).then((result) => {
      let pass = result.data;
      if(!pass.password){
        toast.error("Email dosen't exists")
        return;
      }else if(pass.password !== data.password){
        toast.error("Wrong password");
        return;
      }else{
        localStorage.setItem('agentID',pass.agentID);
        navigate(`/newUser/${pass.agentID}`);
      }
    }).catch((err) => {
      toast.error("Somthing wents wrong");
    });
  }
  return (
    <div className='bg-white h-full w-full z-10 flex justify-center items-center'>
      <ToastContainer/>
      <div className='flex flex-col gap-6 w-10/12 md:w-1/4'>
        <div className='font-bold text-2xl'>Agent Login</div>
        <div className="relative w-full min-w-[200px] h-10">
              <input onChange={useInputHandler} name='email' value={data.email} autoFocus className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
              <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Email
              </label>
        </div>
        <div className="relative w-full min-w-[200px] h-10">
            <input onChange={useInputHandler} name='password' type='password' value={data.password} className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
            <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Password
          </label>
        </div>
        <div onClick={login} className='blue text-white p-2 text-center rounded-md hover:cursor-pointer'>Login</div>
      </div>
    </div>
  )
}

export default AgentLogin