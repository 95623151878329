import React from 'react'
import logo from '../../assets/logo.svg'
import { Link, Outlet } from 'react-router-dom'
function AgentCard({data}) {
  return (
    <div className='p-4 w-9/12 flex items-center justify-between light-green rounded-md'>
        <Outlet/>
        <div className='w-2/5 flex justify-center items-center h-full flex-col'>
            <img src={logo} alt={logo} width='120px'/>
            <div className='font-bold text-2xl'>HealthKard Agent</div>
        </div>
        <div className='w-3/5 flex justify-center h-full flex-col gap-2 items-center'>
            <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Id</div>{data.agentID}</div>
            <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Name </div>{data.name}</div>
            <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Email </div>{data.email}</div>
            <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Password </div>{data.password}</div>
            <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Number </div>{data.number}</div>
            <div className='flex  gap-4 w-full'><div className='font-bold w-1/3'>Joined in </div>{(new Date(data.createDate)).toISOString().split('T')[0]}</div>
            <Link to={`delete/${data.agentID}`} className='red text-white p-2 rounded-md'>Delete Agent</Link>
        </div>
    </div>
  )
}

export default AgentCard