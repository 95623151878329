import React, { useEffect, useState } from 'react'
import PendingNavbar from './PendingNavbar'
import {Outlet, useParams} from 'react-router-dom'
import Table from './Table'
import serverURL from '../../server-config'
import logo from '../../assets/logo.svg'
import axios from 'axios';
function Pending() {
    const {hospitalId}  = useParams();
    let [data,setData] = useState([]);
    const [stats,setStats] = useState({pendingCount:0,approvedCount:0});
    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get(`${serverURL}/verifiedHospitals`).then((response) => {
                    if(response.data){
                        setData(response.data.reverse()); 
                        setFilteredData(response.data);
                    }
                })
                await axios.get(`${serverURL}/hospitalStats`).then((result) => {
                    setStats(result.data);
                })
            } catch (error) {
                console.error('Error fetching hospital data:', error);
            }
        };

        fetchData();
    }, []);
    const [filteredData,setFilteredData] = useState(data);
    // functions
    const filter = (term)=>{
        const newData = data.filter(row=>{
            return (row.hospitalId.toLowerCase().includes(term.toLowerCase())||
            row.hospitalDetails.hospitalLegalName.toLowerCase().includes(term.toLowerCase()));
        })
        setFilteredData(newData);
    }
  return (
    <div className='flex p-4 gap-12 justify-center'>
        <div className='flex flex-col gap-5 w-1/4'>
            <div className='flex lg:text-xl gap-10'>
                <div className='flex flex-col w-1/2 p-4 border-gray-100 rounded-xl border shadow-xl'>
                    <div className='font-semibold'>Pending Hospital</div>
                    <div className='text-green font-bold'>{stats.pendingCount}</div>
                </div>
                <div className='flex flex-col w-1/2 p-4 border-gray-100 rounded-xl border shadow-xl'>
                    <div className='font-semibold'>Approved Hospitals</div>
                    <div className='text-green font-bold'>{stats.approvedCount}</div>
                </div>
            </div>
            <div className='gap-5 flex flex-col'>
                <input type='search' onChange={(e)=>filter(e.target.value)}  placeholder='Search with HealthKard ID or Hospital Name' className='border border-gray-100 shadow-lg w-full p-2 rounded-md'/>
                <Table data={filteredData} dataOf={'Hospital'}/>
            </div>
        </div>
        {hospitalId && <div className='flex flex-col w-2/3 gap-10 '>
            <PendingNavbar/>
            <Outlet/>
        </div>}
        {!hospitalId && <div className='flex flex-col w-2/3 gap-10 justify-center items-center h-[80vh]'>
            <img src={logo} alt='logo' width='300px'/>
            <div className='font-bold text-3xl text-green'>HealthKard</div>
        </div>}
    </div>
  )
}

export default Pending