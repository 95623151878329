import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import './style.css'
function UserSignUp() {
  return (
    <div className='w-[100vw]' id='agent'>
        <div className='w-full flex text-xs md:text-md '>
            <NavLink to='' className={' w-1/3 text-center font-semibold hover:cursor-pointer p-4'}>Registration</NavLink>
            <NavLink to='hospitals' className={' w-1/3 text-center font-semibold hover:cursor-pointer p-4'}>Onboarded Hospitals</NavLink>
            <NavLink to='users' className={' w-1/3 text-center font-semibold hover:cursor-pointer p-4'}>Onboarded Users</NavLink>
        </div>
        <Outlet/>
    </div>
  )
}

export default UserSignUp
