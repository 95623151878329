import axios from 'axios';
import React, {useState } from 'react';
import serverURL from '../../server-config';
import { ToastContainer,toast } from 'react-toastify';  
import { FaDotCircle } from "react-icons/fa";
function Renewal() {
  const [lastPlan,setLastPlan] = useState("one month")
  const setPlan = (plan)=>{
    setLastPlan(plan)
  }
  const [user, setUser] = useState({});
    const [fetched,setFetched] = useState(false);
    const [healthId,setHealthId] = useState("")
    const [number,setNumber] = useState("");
    const [searchBy,setSearchBy] = useState("id");
    const fetchData = async () => {
        setFetched(false)
        if(!healthId && !number){
            toast.error("Please enter the details");
            return;
        }
        if(searchBy==="number" && number.length!==10){
            toast.error("Please enter valid number")
            return;
        }
        try {
            let result;
            if(searchBy === "id")
                result = await axios.get(`${serverURL}/getUser/${healthId}`);
            else
                result = await axios.get(`${serverURL}/getUserByNumber/91${number}`);
            setUser(result.data);
            if(searchBy==="id" && !result.data.image){
                setFetched(false);
                toast.error("User not found");
                return;
            }
            toast.success("User found");
            setFetched(true);
        } catch (err) {
            toast.error("User not found")
            console.error("Error fetching users:", err);
            setFetched(false);
        }
    };
    const changeMode = (e)=>{
        setFetched(false);
        if(e.target.value==="phoneNumber"){
            setSearchBy("number")
        }else{
            setSearchBy("id");
        }
    }
    const renew = ()=>{
        window.location.href = `${serverURL}/pay/?mobileNumber=${user.number}&name=${user.name}&plan=${lastPlan}&healthID=${healthId}&isNew=${false}`
    }
  return (
    <div className='flex flex-col w-full mx-auto items-center'>
        <ToastContainer/>
      <div className='light-green p-4 flex flex-col items-center gap-4'>
        <div className='flex gap-4 text-sm lg:text-xl'>
            <label htmlFor='healthkardid' className='flex items-center p-1 gap-2 hover:cursor-pointer'>
                <input onClick={changeMode} checked={searchBy==="id"} type='radio' id='healthkardid' className='' name='type' value="healthkardid"/>
                <span>Search by healthKard ID</span>
            </label>
            <label htmlFor='phoneNumber' className='flex items-center p-1 gap-2 hover:cursor-pointer'>
                <input onClick={changeMode} checked={searchBy!=="id"} type='radio' id='phoneNumber' className='' name='type' value="phoneNumber"/>
                <span>Search by Mobile Number</span>
            </label>
        </div>
        {searchBy==="id" && <div className='w-full flex flex-col lg:flex-row items-center gap-4'>
            <div className=''>Enter Healthkard ID</div>
            <div className=''><input onChange={(e)=>setHealthId(e.target.value)} type='text' className='p-2 rounded-md' placeholder='HK24XXXXXXX'/></div>
            <div onClick={fetchData} className='p-2 blue text-white rounded-md hover:cursor-pointer'>Validate</div>
        </div>}
        {searchBy==="number" && <div className='w-full flex flex-col lg:flex-row items-center gap-4'>
            <div className=''>Enter Mobile Number</div>
            <div className=''><input onChange={(e)=>setNumber(e.target.value)} type='number' className='p-2 rounded-md' placeholder='987XXXXXX01'/></div>
            <div onClick={fetchData} className='p-2 blue text-white rounded-md hover:cursor-pointer'>Validate</div>
        </div>}
      </div>
      {fetched && searchBy==="id" && <div className='flex flex-col gap-2'>
        <div className=''>
            {user.gender==='male'?"Mr. ":" Smt. "} {user.name} Your validity expires in {(new Date(user.expireDate)).toLocaleDateString()}
        </div>
      </div>}
      {fetched && searchBy==="number" && 
      <div className='flex flex-col gap-2'>
        <div className=''>Please select the card</div>
        <div className='flex flex-wrap gap-2'>
        {
            user.map((u,index)=>{
                return(
                    <label htmlFor={`healthkard${index}`} className='flex gap-2 border p-2 rounded-md'>
                        <input onClick={e=>setHealthId(e.target.value)} type='radio' name='healthkard' value={u.healthId}/>
                        <div className=''>
                            <div className='font-semibold text-xl'>{u.name}</div>
                            <div className='text-xs'>{u.healthId}</div>
                        </div>
                    </label>
                )
            })
        }
        </div>
      </div>}
        {fetched&& <div className='w-full p-2 flex flex-wrap items-center justify-center gap-3'>
            <div onClick={()=>setPlan('one month')} className={`flex items-center gap-2 p-2 w-full border-2 rounded-md bg-neutral-200 hover:cursor-pointer ${lastPlan==='one month'?'text-green':''}`}>
                <div className=''>
                    <FaDotCircle />
                </div>
                <div className=''>
                    <div className=''>Monthly plan</div>
                    <div className=''>&#x20B9; 99</div>
                </div>
            </div>
            <div onClick={()=>setPlan('three months')} className={`flex items-center gap-2 p-2 w-full border-2 rounded-md bg-neutral-200 hover:cursor-pointer ${lastPlan==='three months'?'text-green':''}`}>
                <div className=''>
                    <FaDotCircle />
                </div>
                <div className=''>
                    <div className=''>Three months plan</div>
                    <div className=''>&#x20B9; 297</div>
                </div>
            </div>
            <div onClick={()=>setPlan('six months')} className={`flex items-center gap-2 p-2 w-full border-2 rounded-md bg-neutral-200 hover:cursor-pointer ${lastPlan==='six months'?'text-green':''}`}>
                <div className=''>
                    <FaDotCircle />
                </div>
                <div className=''>
                    <div className=''>Six months plan</div>
                    <div className=''>&#x20B9; 499</div>
                </div>
            </div>
            <div onClick={()=>setPlan('one year')} className={`flex items-center gap-2 p-2 w-full border-2 rounded-md bg-neutral-200 hover:cursor-pointer ${lastPlan==='one year'?'text-green':''}`}>
                <div className=''>
                    <FaDotCircle />
                </div>
                <div className=''>
                    <div className=''>Yearly plan</div>
                    <div className=''>&#x20B9; 899</div>
                </div>
            </div>
        </div>}
      {fetched && <div onClick={renew} className=' my-4 blue text-white text-center text-xl w-8/12 p-2 rounded-md hover:cursor-pointer'>Pay</div>}
    </div>
  )
}

export default Renewal