import React from 'react'

function AgentTable({data}) { 
    
  return (
    <div className='flex flex-col w-full'>
        {data.map((d,index)=>{
            return(
                <div key={index} className={`flex p-2 ${index%2===0?'light-green':''}`}>
                    <div className='w-3/12'>{d.healthID||d.hospitalId}</div>
                    <div className='w-1/2'>{d.name}</div>
                    <div className='w-3/12'>{(new Date(d.date)).toISOString().split('T')[0]}</div>
                </div>
            )
        })}

    </div>
  )
}

export default AgentTable