import React, { useEffect, useState } from 'react'
import { auth } from '../../firebase-config';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { CiCamera } from "react-icons/ci";
import PhoneInput from 'react-phone-input-2'
import OTPInput from "otp-input-react";
import { ToastContainer, toast } from 'react-toastify';
import Webcam from "react-webcam";
import axios from 'axios';
import { FaDotCircle } from "react-icons/fa";
import serverURL from '../../server-config';
import { MdCameraswitch } from "react-icons/md";
function NewUser() {
    const [enableCamera, setEnableCamera] = useState(false);
    const [number, setNumber] = useState(null);
    const [user, setUser] = useState(null);
    const [otp, setOtp] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    // const {agentID} = useParams();
    const [agents, setAgents] = useState([]);
    const [agentID, setAgenetID] = useState("Self");
    const [videoConstraints, setVideoConstraints] = useState({
        facingMode: { exact: "environment" }
    });
    const toggleFacing = () => {
        if (videoConstraints.facingMode === "user") {
            setVideoConstraints({
                facingMode: { exact: "environment" }
            });
        } else {
            setVideoConstraints({
                facingMode: "user"
            })
        }
    }
    const generateUniqueId = () => {
        const date = new Date();
        const prefix = "HK" + date.getFullYear().toString().slice(-2);
        const seconds = date.getSeconds();
        const minutes = date.getMinutes();
        const milliseconds = date.getMilliseconds();
        const uniqueId = String(seconds).padStart(2, '0') +
            String(minutes).padStart(2, '0') +
            String(milliseconds).padStart(3, '0');

        return prefix + uniqueId;
    }
    const [userData, setUserData] = useState(() => {
        let healthId = generateUniqueId();
        let currentDate = new Date();
        let expireDate = new Date(currentDate.getTime());
        expireDate.setDate(currentDate.getDate() + 28);

        return {
            dateJoined: currentDate,
            healthId: healthId,
            agent: agentID,
            name: "",
            age: "",
            gender: "male",
            number: "",
            email: "",
            address: "",
            lastPlan: "one month",
            city: "",
            pincode: "",
            image: "",
            startDate: currentDate,
            expireDate: expireDate,
            visited: []
        };
    });
    const [image, setImage] = useState(null);
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setUserData(prevUserData => ({
            ...prevUserData,
            image: imageSrc
        }));
        setImage(imageSrc);
    }, [webcamRef]);


    const useInputHandler = (e) => {
        setUserData(prevUserData => ({
            ...prevUserData,
            [e.target.name]: e.target.value
        }));
    }

    useEffect(() => {
        setUserData(prevUserData => ({
            ...prevUserData,
            number: number
        }));
    }, [number])
    // const handleSendOTP = async () => {
    //     setLoading(true);
    //     try {
    //         const recaptcha = new RecaptchaVerifier(auth, "recaptcha", { 'size': 'invisible' });
    //         const confirmation = await signInWithPhoneNumber(auth, '+' + number, recaptcha);
    //         if (confirmation) {
    //             document.getElementById('recaptcha').style.display = 'none';
    //             setUser(confirmation);
    //             setLoading(false);
    //             setOtpSent(true);
    //         }
    //     } catch (error) {
    //         document.getElementById('recaptcha').style.display = 'none';
    //         console.log(error)
    //         toast.error('Error while sending otp');
    //         setLoading(false);
    //     }
    // }

    // const handleVerifyOTP = async () => {
    //     setLoading(true);
    //     try {
    //         await user.confirm(otp);
    //         setLoading(false);
    //         setVerified(true);
    //         setUserData(previous => {
    //             return {
    //                 ...previous,
    //                 number: number
    //             }
    //         })
    //     } catch (error) {
    //         toast.error("Please re-enter the correct otp");
    //         setLoading(false);
    //     }
    // }
    let plans = { 'one month': 28, 'three months': 84, 'six months': 168, 'one year': 336 };
    const setPlan = (plan) => {
        let days = plans[plan];
        let currentDate = new Date();
        let expireDate = new Date(currentDate.getTime());
        expireDate.setDate(currentDate.getDate() + days);
        setUserData(prevUserData => ({
            ...prevUserData,
            lastPlan: plan,
            expireDate: expireDate
        }));
    }
    const submit = async () => {
        // Update userData with agentID
        const updatedUserData = { ...userData, agent: agentID };
        setUserData(updatedUserData);

        // Validate email
        if (updatedUserData.email && !updatedUserData.email.includes('@gmail.com')) {
            toast.error("Please enter a valid email");
            return;
        }

        // Check for required fields
        const requiredFields = ['name', 'age', 'gender', 'number', 'address', 'city', 'pincode', 'image'];
        for (let field of requiredFields) {
            if (!updatedUserData[field]) {
                toast.error("Please fill all the details");
                return;
            }
        }
        // console.log(userData);
        // return;
        try {
            await axios.post(`${serverURL}/addUser`, updatedUserData).then(async (result) => {
                if (agentID !== "Self") {
                    await axios.put(`${serverURL}/addUser/${agentID}`, {
                        healthID: updatedUserData.healthId,
                        name: updatedUserData.name
                    }).then((result) => {

                    }).catch((err) => {
                        toast.error("Agent not found");
                    });;
                }
                toast.success("Updated successfully")
            }).catch((err) => {
                toast.error("Please check the data");
            });;
            window.location.href = `${serverURL}/pay/?mobileNumber=${updatedUserData.number}&name=${updatedUserData.name}&plan=${updatedUserData.lastPlan}&healthID=${updatedUserData.healthId}&isNew=${true}`;
            toast.success("Data saved successfully");
        } catch (error) {
            toast.error("An error occurred");
            console.error(error);
        }
    };

    const fetchAgents = async () => {
        const result = await axios.get(`${serverURL}/agents`);
        setAgents(result.data);
    }
    useEffect(() => {
        fetchAgents();
    }, [])
    return (
        <div className='p-6 flex flex-col items-center gap-4 w-full mx-auto'>
            <ToastContainer />
            <div className='text-2xl font-bold'>User Registration form</div>
            <div className="w-full">
                <div className="relative w-full min-w-[200px] h-10">
                    <input onChange={ useInputHandler } name='name' value={ userData.name } autoFocus className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Name
                    </label>
                </div>
            </div>
            <div className='flex justify-between items-center w-full gap-4'>
                <div className="w-1/2">
                    <div className="relative h-10">
                        <input onChange={ useInputHandler } name='age' type='number' value={ userData.age } className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                        <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Age
                        </label>
                    </div>
                </div>
                <div className='flex gap-3 w-1/2 items-center'>
                    <label htmlFor='gender' className='font-bold'>Select Gender</label>
                    <select id='gender' className='px-2 border-black border p-2 rounded-md' name='gender' onChange={ useInputHandler } >
                        <option>--select--</option>
                        <option className='p-2' value='male'>Male</option>
                        <option className='p-2' value='female'>Female</option>
                        <option className='p-2' value='others'>Others</option>
                    </select>
                </div>
            </div>
            <div className="w-full flex  flex-col gap-2">
                <PhoneInput country={ 'in' } name="number" value={ number } onChange={ phone => setNumber(phone) } disabled={ verified } />
                {/*<div id='recaptcha'></div>
             {otpSent && !verified && <OTPInput value={otp}  onChange={(e)=>setOtp(e)} autoFocus OTPLength={6} inputClassName='border b-blue' otpType="number"  secure />}
            {!otpSent && !verified && <div onClick={handleSendOTP} className='w-fit blue text-white px-2 py-1 hover:cursor-pointer'>{loading?'Sending OTP':'Send OTP'}</div>}
            {otpSent && !verified && <div onClick={handleVerifyOTP} className='w-fit blue text-white px-2 py-1 hover:cursor-pointer'>{loading?'Verifying OTP':'Verify OTP'}</div>}
            {otpSent && verified && <div className='w-fit text-green px-2 py-1'>Verified</div>} */}
            </div>
            <div className="w-full">
                <div className="relative w-full min-w-[200px] h-10">
                    <input onChange={ useInputHandler } name='email' value={ userData.email } className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Email (Optional)
                    </label>
                </div>
            </div>
            <div className="w-full">
                <div className="relative w-full min-w-[200px] h-10">
                    <input onChange={ useInputHandler } name='address' value={ userData.address } className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                    <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Address
                    </label>
                </div>
            </div>
            <div className='w-full flex justify-between gap-4'>
                <div className="w-full">
                    <div className="relative w-full  h-10">
                        <input onChange={ useInputHandler } name='city' value={ userData.city } className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                        <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Town/City
                        </label>
                    </div>
                </div>
                <div className="w-full">
                    <div className="relative w-full h-10">
                        <input onChange={ useInputHandler } name='pincode' value={ userData.pincode } className="border- peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200  focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-5 rounded-[5px]  focus:border-gray-900" placeholder=" " />
                        <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Pin code
                        </label>
                    </div>
                </div>
            </div>
            <div className='w-full p-2 flex flex-wrap gap-3'>
                <div onClick={ () => setPlan('one month') } className={ `flex items-center gap-2 p-2 w-2/5 lg:w-1/4 border-2 rounded-md bg-neutral-200 hover:cursor-pointer ${userData.lastPlan === 'one month' ? 'text-green' : ''}` }>
                    <div className=''>
                        <FaDotCircle />
                    </div>
                    <div className=''>
                        <div className=''>Monthly plan</div>
                        <div className=''>&#x20B9; 99</div>
                    </div>
                </div>
                <div onClick={ () => setPlan('three months') } className={ `flex items-center gap-2 p-2 w-2/5 lg:w-1/4 border-2 rounded-md bg-neutral-200 hover:cursor-pointer ${userData.lastPlan === 'three months' ? 'text-green' : ''}` }>
                    <div className=''>
                        <FaDotCircle />
                    </div>
                    <div className=''>
                        <div className=''>Three months plan</div>
                        <div className=''>&#x20B9; 297</div>
                    </div>
                </div>
                <div onClick={ () => setPlan('six months') } className={ `flex items-center gap-2 p-2 w-2/5 lg:w-1/4 border-2 rounded-md bg-neutral-200 hover:cursor-pointer ${userData.lastPlan === 'six months' ? 'text-green' : ''}` }>
                    <div className=''>
                        <FaDotCircle />
                    </div>
                    <div className=''>
                        <div className=''>Six months plan</div>
                        <div className=''>&#x20B9; 499</div>
                    </div>
                </div>
                <div onClick={ () => setPlan('one year') } className={ `flex items-center gap-2 p-2 w-2/5 lg:w-1/4 border-2 rounded-md bg-neutral-200 hover:cursor-pointer ${userData.lastPlan === 'one year' ? 'text-green' : ''}` }>
                    <div className=''>
                        <FaDotCircle />
                    </div>
                    <div className=''>
                        <div className=''>Yearly plan</div>
                        <div className=''>&#x20B9; 899</div>
                    </div>
                </div>
            </div>
            { enableCamera && !image &&
                <div className='md:w-1/2'>
                    <Webcam
                        audio={ false }
                        height={ 720 }
                        ref={ webcamRef }
                        videoConstraints={ videoConstraints }
                        screenshotFormat="image/jpeg"
                        width={ 1280 } />
                </div> }
            { image &&
                <div className=''>
                    <img src={ image } alt={ image } />
                </div> }
            { !enableCamera && <div onClick={ () => setEnableCamera(!enableCamera) } className='hover:cursor-pointer w-full flex flex-col justify-center items-center bg-gray-200 p-2 border-gray-500 rounded-md border '>
                <CiCamera className='text-6xl' />
                <div className=''>Take a picture</div>
            </div> }
            { enableCamera && !image && <div onClick={ toggleFacing } className='hover:cursor-pointer w-fit flex justify-center items-center bg-gray-200 p-2 border-gray-500 rounded-md border  gap-2 '><MdCameraswitch /><div className=''>Revert</div></div> }
            { enableCamera && !image && <div onClick={ capture } className='hover:cursor-pointer w-full flex flex-col justify-center items-center bg-gray-200 p-2 border-gray-500 rounded-md border'>
                Capture the image
            </div> }
            { enableCamera && image && <div onClick={ () => { setEnableCamera(!enableCamera); setImage(null); } } className='hover:cursor-pointer w-full flex flex-col justify-center items-center bg-gray-200 p-2 border-gray-500 rounded-md border'>Retake the image</div> }
            <div className='flex flex-col lg:flex-row gap-2 items-center'>
                <div className=''>Are you recommended by any agent ?</div>
                <select onChange={ (e) => setAgenetID(e.target.value) } value={ agentID } className='border border-black rounded-md p-2'>
                    <option>Self</option>
                    { agents.map((agnt, index) => {
                        return (
                            <option key={ index } value={ agnt.agentID } className=''>
                                { agnt.name } - { agnt.agentID }
                            </option>
                        )
                    }) }
                </select>
            </div>
            <div onClick={ submit } className='hover:cursor-pointer green rounded-md p-2 w-full items-center flex justify-center font-bold  text-white'>
                Pay and Submit
            </div>
        </div>
    )
}

export default NewUser