import React, { useEffect, useState } from 'react'
import UserInfoTable from './UserInfoTable'
import { Link, Outlet, useParams } from 'react-router-dom';
import axios from 'axios';
import serverURL from '../../server-config';
import { ToastContainer, toast } from 'react-toastify';
import UserDeletePopup from './UserDeletePopup';
function  UserInfo() {
  const [user,setUser]=useState({});
  const {healthId} = useParams();
  let fetchData =async ()=>{
      await axios.get(`${serverURL}/getUser/${healthId}`).then((result) => {
          setUser (result.data);
      }).catch((err) => {
          
      });
  }
  useEffect(()=>{
      fetchData();
  },[healthId]);
  const updatePaymentStatus = async () => {
    try {
      await axios.put(`${serverURL}/payment/${healthId}`, { paymentStatus: true });
      toast.success("Updated successfully");
      await fetchData();
    } catch (error) {
      console.error(error);
      toast.error("Failed while updating");
    }
  };
  
  return (
    <div className='flex flex-col w-2/3 gap-10 '>
      <ToastContainer/>
      <Outlet/>
      <div className='card shadow-lg p-4 rounded flex justify-between items-center gap-2'>
        <div className='flex flex-col items-center gap-2 w-1/3'>
          <img src={user.image} width='200px' className='aspect-square rounded-full' alt='userprofile'/>
          <div className='font-bold'>{user.healthId}</div>
        </div>
        <div className=' flex flex-col gap-2 w-2/3 '>
          <div className='flex items-center gap-2'><div className='font-bold'>Name : </div>{user.name}</div>
          <div className='flex gap-2'>
            <div className='flex items-center gap-2'><div className='font-bold'>Gender : </div>{user.gender}  </div>
            <div className='flex items-center gap-2'><div className='font-bold'>Age : </div> {user.age}</div>
          </div>
          <div className='flex items-center gap-2'><div className='font-bold'>Number : </div>{user.number}</div>
          {user.email && <div className='flex items-center gap-2'><div className='font-bold'>Email : </div>{user.email}</div>}
          <div className='flex items-center gap-2'><div className='font-bold'>Address : </div> {user.address}, {user.city}, {user.pincode}</div>
          {user.expireDate&&<div className='flex items-center gap-2'><div className='font-bold'>Validity till : </div>{user.paymentStatus ?(new Date(user.expireDate).toISOString().split('T')[0]):"Invalid"}</div>}
          <div className=' flex gap-2 items-center'>
            {!user.paymentStatus && <div onClick={updatePaymentStatus} className='p-2 green text-white w-fit rounded-md hover:cursor-pointer'>Mark as Paid</div>}
            <Link to={`delete/${healthId}`} className='red text-white p-2 rounded-md'>Delete user</Link>
          </div>
        </div>
      </div>
      <UserInfoTable data={user.visited}/>
    </div>
  )
}

export default UserInfo