import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './client/Home';
import HospitalDashboard from './hospital/HospitalDashboard';
import Form1 from './hospital/components_register/Form1';
import Form2 from './hospital/components_register/Form2';
import HospitalRegister from './hospital/HospitalRegister';
import Form3 from './hospital/components_register/Form3';
import HospitalCredentails from './hospital/HospitalCredentails';
import SignUp from './hospital/components/SignUp';
import Login from './hospital/components/Login';
import Dashboard from './hospital/components_dashboard/Dashboard';
import Profile from './hospital/components_dashboard/Profile';
import SuccessPopup from './hospital/components_register/SuccessPopup';
import Techniqal from './techniqal/Techniqal';
import Pending from './techniqal/components/Pending';
import HospitalDetails from './techniqal/components/HospitalDetails';
import DoctorDetails from './techniqal/components/DoctorDetails';
import MediaDetails from './techniqal/components/MediaDetails';
import EditHospitalDetails from './techniqal/components/EditHospitalDetails';
import DenyHospital from './techniqal/components/DenyHospital';
import Approved from './techniqal/components/Approved';
import UsersInfo from './techniqal/components/UsersInfo';
import HospitalEditPersonal from './hospital/components_dashboard/HospitalEditPersonal'
import HospitalHospitalDetails from './hospital/components_dashboard/HospitalHospitalDetails'
import HospitailDoctorDetails from './hospital/components_dashboard/HospitailDoctorDetails'
import HospitalMediaDetails from './hospital/components_dashboard/HospitalMediaDetails'
import TechnicalLogin from './techniqal/credentails.js/TechnicalLogin';
import DeleteHospital from './techniqal/components/DeleteHospital';
// client
import UserSignUp from './client/credentials/UserSignUp'
import Agents from './techniqal/agents/Agents';
import AgentAddForm from './techniqal/agents/AgentAddForm';
import AgentInfo from './techniqal/agents/AgentInfo';
import AgentLogin from './client/credentials/AgentLogin';
import AgentDownloadCard from './techniqal/agents/AgentDownloadCard';
import UserCard from './client/components/UserCard';
import UserInfo from './techniqal/components/UserInfo';
import HospitalUserInfo from './hospital/components/UserInfo';
import UserDefineRoutes from './UserDefineRoutes';
import HospitalsList from './client/pages/HospitalsList';
import HospitalUserDetails from './client/pages/HospitalUserDetails';
import TermsAndConditionPopup from './components/TermsAndConditionPopup';
import PrivacyPolicyPopup from './components/PrivacyPolicyPopup';
import RefundPolicies from './components/RefundPolicies';
import UserRenewalForm from './components/UserRenewalForm';
import NewUser from './components/UserSignupComponents/NewUser';
import Renewal from './components/UserSignupComponents/Renewal';
import CardList from './components/UserSignupComponents/CardList';
import UserDeletePopup from './techniqal/components/UserDeletePopup';
import DeleteAgentPopup from './techniqal/agents/DeleteAgentPopup';
import HospitalPolicyPopup from './hospital/components/HospitalPolicyPopup';
import UserRegister from './client/pages/agents/UserRegister';
import HospitalsOnboarded from './client/pages/agents/HospitalsOnboarded';
import UsersOnboarded from './client/pages/agents/UsersOnboarded';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}>
          <Route path='/' element={<UserDefineRoutes/>}>
            <Route path='termsAndConditions' element={<TermsAndConditionPopup/>}/>
            <Route path='privacyPolicy' element={<PrivacyPolicyPopup/>}/>
            <Route path='refundPolicy' element={<RefundPolicies/>}/>
          </Route>
          <Route path='userRenewalFrom' element={<UserRenewalForm/>}>
            <Route path='card' element={<CardList/>}/>
            <Route path='renewal' element={<Renewal/>}/>
            <Route path='newUser' element={<NewUser/>}/>
          </Route>
          <Route path='agentLogin' element={<AgentLogin/>}></Route>
          <Route path='newUser/:agentID' element={<UserSignUp/>}>
            <Route path='' index element={<UserRegister/>}/>
            <Route path='hospitals' element={<HospitalsOnboarded/>}/>
            <Route path='users' element={<UsersOnboarded/>}/>
          </Route>
          <Route path='userCard/:healthId' element={<UserCard/>}></Route>
        </Route>
        <Route path='/hospital' element={<HospitalDashboard/>}>
          <Route path='dashboard' element={<Dashboard/>}>
            <Route path='user/:healthId' element={<HospitalUserInfo/>}/>
          </Route>
          <Route index path='' element={<Dashboard/>}></Route>
          <Route path='profile' element={<Profile/>}>
            <Route path='hospitalDetails' element={<HospitalHospitalDetails/>}></Route>
            <Route path='doctorDetails' element={<HospitailDoctorDetails/>}></Route>
            <Route path='mediaDetails' element={<HospitalMediaDetails/>}></Route>
            <Route path='edit' element={<HospitalEditPersonal/>}></Route>
          </Route>
        </Route>
        <Route path='/auth' element={<HospitalCredentails/>}>
          <Route path='signup' element={<SignUp/>}>
            <Route path='hospitalPolicies' element={<HospitalPolicyPopup/>}/>
          </Route>
          <Route path='login' element={<Login/>}/>
          <Route path='termsAndConditions' element={<TermsAndConditionPopup/>}/>
          <Route path='privacyPolicy' element={<PrivacyPolicyPopup/>}/>
          <Route path='refundPolicy' element={<RefundPolicies/>}/>
        </Route>
        <Route path='/listOfHospitals' element={<HospitalsList/>}/>
        <Route path='/hospital'>
          <Route path=':hospitalId' element={<HospitalUserDetails/>}/>
        </Route>
        <Route path='/hospitalRegister' element={<HospitalRegister/>}>
          <Route index path='hospitalDetails' element={<Form1/>}></Route>
          <Route path='doctorDetails' element={<Form2/>}></Route>
          <Route path='mediaDetails' element={<Form3/>}></Route>
          <Route path='success' element={<SuccessPopup/>}></Route>
        </Route>
        <Route path='/techniqal' element={<Techniqal/>}>
          <Route path='login' element={<TechnicalLogin/>}></Route>
          <Route path='pending' element={<Pending/>}>
            <Route path='hospitalDetails/:hospitalId' element={<HospitalDetails/>}></Route>
            <Route path='doctorDetails/:hospitalId' element={<DoctorDetails/>}></Route>
            <Route path='mediaDetails/:hospitalId' element={<MediaDetails/>}></Route>
            <Route path='edit/:hospitalId' element={<EditHospitalDetails/>}></Route>
            <Route path='deny/:hospitalId' element={<DenyHospital/>}></Route>
          </Route>
          <Route path='approved' element={<Approved/>}>
            <Route path='hospitalDetails/:hospitalId'  element={<HospitalDetails/>}></Route>
            <Route path='doctorDetails/:hospitalId' element={<DoctorDetails/>}></Route>
            <Route path='mediaDetails/:hospitalId' element={<MediaDetails/>}></Route>
            <Route path='edit/:hospitalId' element={<EditHospitalDetails/>}></Route>
            <Route path='deleteHospital/:hospitalId' element={<DeleteHospital/>}></Route>
          </Route>
          <Route path='users' element={<UsersInfo/>}>
            <Route path='user/:healthId'  element={<UserInfo/>}>
              <Route path='delete/:healthId' element={<UserDeletePopup/>}/>
            </Route>
          </Route>
          <Route path='agents' element={<Agents/>}>
            <Route path='addAgent' element={<AgentAddForm/>}/>
            <Route path=':agentID' element={<AgentInfo/>}>
              <Route path='delete/:agentID' element={<DeleteAgentPopup/>}/>
            </Route>
            <Route path='agentDownloadCard/:agentID' element={<AgentDownloadCard/>}/>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
