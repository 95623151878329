import React from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'
import {  FaHome } from "react-icons/fa";
import './UserSignupComponents/style.css';
import HomeNavbar from './HomeNavbar';
function UserRenewalForm() {
  return (
    <div id='userRenewalFrom' className='w-full flex flex-col items-center'>
        <HomeNavbar/>
        <div className='flex w-full lg:w-8/12 p-2'>
          <Link to='/' className='flex items-center gap-2 my-2 p-3'><FaHome />Home</Link>
        </div>
        <div className='flex w-full lg:w-8/12 p-2'>
            <NavLink to='card' className='flex justify-center items-center w-1/3 p-2 text-sm lg:text-xl'>View Your Kard</NavLink>
            <NavLink to='newUser' className='flex justify-center items-center w-1/3 p-2 text-sm lg:text-xl'>New User</NavLink>
            <NavLink to='renewal' className='flex justify-center items-center w-1/3 p-2 text-sm lg:text-xl'>Renewal </NavLink>
        </div>
        <div className='w-full lg:w-8/12 p-2'>
            <Outlet/>
        </div>
    </div>
  )
}

export default UserRenewalForm