import React, { useState } from 'react'
import logo from '../assets/logo.svg'
import { Link, useLocation } from 'react-router-dom'
import { CiMenuFries } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
function HomeNavbar({handleScroll,aboutRef,stepsToJoinRef,featuresRef,hospitalRef,plansRef,contactUsRef}) {
  const location = useLocation();
  const [showNavItems,setShowNavItems] = useState(false);
  return (
    <div className={`${location.pathname.includes('userRenewalFrom')?'bg-white':''} flex sticky z-10 top-0 left-0 w-full justify-between gap-2 p-4 items-center hover:bg-gray-100 `}>
        <Link to='/' className=' flex justify-center items-center gap-2 text-green font-semibold'>
            <img src={logo} width='42px'/>
            <div className=' text-xl font-bold'>Healthkard</div>
        </Link>
        {!location.pathname.includes('userRenewalFrom') &&
          <div className='hidden lg:flex gap-10 items-center text-lg  '>
            <div onClick={()=>handleScroll(aboutRef)} className='p-2 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>About us</div>
            <div onClick={()=>handleScroll(stepsToJoinRef)} className='p-2 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Steps to Join</div>
            <div onClick={()=>handleScroll(featuresRef)} className='p-2 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Features</div>
            <div onClick={()=>handleScroll(hospitalRef)} className='p-2 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Hospitals</div>
            <div onClick={()=>handleScroll(plansRef)} className='p-2 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Plans</div>
            <Link to='/auth' className='p-2 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Hospital Login</Link>
            <div onClick={()=>handleScroll(contactUsRef)} className='p-2 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Contact Us</div>
        </div>}
        <div className=' flex gap-2 justify-end items-center'>
            <Link to='/userRenewalFrom/newUser' className=' green p-2 text-center text-white rounded-md text-xs lg:text-lg z-10'>Sign Up</Link>
            {!location.pathname.includes('userRenewalFrom') &&<div onClick={()=>setShowNavItems(true)}  className='lg:hidden p-2'><CiMenuFries/></div>}
        </div>
        {showNavItems && <div className='lg:hidden z-10  w-3/5 light-green fixed right-0 top-4 p-10'>
          <div onClick={()=>setShowNavItems(false)} className='w-full flex justify-end font-bold text-red-500'><RxCross1/></div>
          {!location.pathname.includes('userRenewalFrom') &&
            <div className='flex flex-col text-lg '>
              <div onClick={()=>handleScroll(aboutRef)} className='p-1 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>About us</div>
              <div onClick={()=>handleScroll(stepsToJoinRef)} className='p-1 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Steps to Join</div>
              <div onClick={()=>handleScroll(featuresRef)} className='p-1 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Features</div>
              <div onClick={()=>handleScroll(hospitalRef)} className='p-1 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Hospitals</div>
              <div onClick={()=>handleScroll(plansRef)} className='p-1 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Plans</div>
              <Link to='/auth' className='p-1 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Hospital Login</Link>
              <div onClick={()=>handleScroll(contactUsRef)} className='p-1 hover:bg-green-100 hover:font-semibold rounded-md hover:cursor-pointer'>Contact Us</div>
          </div>}
        </div>}
    </div>
  )
}

export default HomeNavbar