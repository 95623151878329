import React from 'react'
import Logo from '../../Logo'
import { useLocation } from 'react-router-dom'

function Navbar() {
  const location = useLocation();
  return (
    <div className= {`${location.pathname.includes('hospitalRegister')?'':'hidden'}  green z-10 sticky top-0  h-[10vh]  lg:left-10 text-green font-bold text-4xl flex items-center pl-12 border-b-2`}>
      <Logo/>
    </div>
  )
}

export default Navbar